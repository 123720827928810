.roomContainer {
  display: flex;
}

.ratesList {
  flex: 3 1 75%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 767px) {
  .roomContainer {
    flex-direction: column;
  }
}