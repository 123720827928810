// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.root {
  @include font-defaults;
}

.title_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  text-decoration: underline;
  color: darkslategrey;
}

.actions_link_title {
  text-align: right;
}

.actions_link {
  display: inline-flex;
  text-align: right;
  align-items: center;
  color: $tbf-blue;
  cursor: pointer;
  justify-content: flex-end;
  width: auto;
  float: right;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $tbf-blue;
  cursor: pointer;
}
