.list {
  --grid-gap: 10px;
  display: grid;
  grid-gap: var(--grid-gap);
  height: 454px;
  max-width: 100%;
}

.list__oneOrTwo {
  grid-template-columns: 100%;
}

.list__threeOrFour {
  grid-template-columns: 75% calc(25% - var(--grid-gap));
}

.list__fiveOrMore {
  grid-template-columns: 50% calc(25% - var(--grid-gap)) calc(25% - var(--grid-gap));
}

.item img {
  object-fit: cover;
  cursor: pointer;
  height: 222px;
}

.item:first-child img {
  height: 454px;
}

.list__oneOrTwo .item--1 {
  grid-column: 1;
  grid-row: 1;
}

.list__oneOrTwo .item--2 {
  display: none;
}

.list__threeOrFour .item--1 {
  grid-column: 1;
  grid-row: 1 / 3;
}

.list__threeOrFour .item--2 {
  grid-column: 2;
  grid-row: 1 / 2;
}

.list__threeOrFour .item--3 {
  grid-column: 2;
  grid-row: 2;
}

.list__threeOrFour .item--4 {
  display: none;
}

.list__fiveOrMore .item--1 {
  grid-column: 1;
  grid-row: 1 / 3;
}

.list__fiveOrMore .item--2 {
  grid-column: 2;
  grid-row: 1 / 2;
}

.list__fiveOrMore .item--3 {
  grid-column: 3;
  grid-row: 1 / 2;
}

.list__fiveOrMore .item--4 {
  grid-column: 2;
  grid-row: 2;
}

.list__fiveOrMore .item--5 {
  grid-column: 3;
  grid-row: 2;
}

@media screen and (max-width: 991px) {
  .list {
    grid-template-columns: 100%;
  }
}
