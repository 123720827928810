// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.root {
  @include font-defaults;
}

.loading_container {
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.loading_description {
  padding-top: 20px;
}