@import 'variables.scss';
@import 'typography.scss';

.root {
  width: 100%;
  color: $tbf-black;
  padding: 0;

  :global(.ant-drawer-footer) {
    border-top: none;
  }
}

@media screen and (max-width: 767px) {
  .root {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.textContainer {
  padding: 0px 20px 20px 20px;
}

.giftCardTitle {
  text-align: left;
  margin-bottom: 30px;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #a4afbe;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
}

.giftCardDescription {
  text-align: left;
  margin-bottom: 25px;
  text-align: justify;
  text-justify: inter-word;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.giftCardExpiration {
  font-size: 16px;
  margin-bottom: 25px;
  color: #6c7683;
  font-weight: 700;
  line-height: 24px;
}

.giftCardSummaryHeader {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.submitButton {
  width: 250px;
  height: 50px;
  margin-left: 20px;
}

.quantity {
  display: inline;
}

.quantityButtonWrapper {
  border: 2px solid $tbf-black;
  width: 250px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.quantityButton {
  border: none !important;
  box-shadow: none;
}

.quantityInput {
  width: 43px;
  height: 32px;
  border: none;
  cursor: text;
  text-align: center;
  font-weight: 700;
}

.textColumn {
  padding-left: 10px;
}

.summaryContainer {
  border: 1px solid #d8dce1;
  width: fit-content;

  .summaryTitle {
    border-bottom: 1px solid $tbf-lightGrey;
    padding: 20px;
    font-size: 18px;
  }

  .summaryTotal {
    padding: 30px 20px 20px 20px;
    font-weight: 700;
    font-size: 20px;
  }
}

.total {
  color: #808080;
  font-size: 20px;
  line-height: 32px;
}

.summary {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}

.discountPrice {
  display: none;
  text-align: right;
}

.netPrice {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.submitSection {
  padding: 30px 20px;
}

@media screen and (max-width: 1215px) {
  .giftCardTitle {
    font-size: 28px;
    line-height: 32px;
  }

  .giftCardDescription {
    font-size: 14px;
    line-height: 20px;
  }

  .giftCardExpiration {
    font-size: 14px;
    line-height: 20px;
  }

  .textContainer {
    padding: 30px 0px;
  }

  .discountPrice {
    display: initial;
  }

  .summary {
    font-size: 20px;
    line-height: 24px;
  }

  .total {
    font-size: 16px;
    line-height: 24px;
  }

  .submitButton,
  .quantityButtonWrapper {
    width: 160px;
    height: 50px;
  }

  .summaryContainer {
    border: none;
    border-top: 1px solid $tbf-lightGrey;

    .summaryTotal {
      padding: 30px 0px 0px 0px;
    }

    .summaryTitle {
      padding: 20px 0;
    }
  }

  .submitSection {
    padding: 30px 0;
  }
}

.bottomContainer {
  margin-bottom: 0px;
  margin-top: auto;
}

.totalAmount {
  text-align: right;
  font-size: 20px;
  line-height: 32px;
  color: $tbf-black;
}

.bottomRow {
  margin-bottom: 20px;
  border-bottom: 1px solid #dbdbdb;
  font-weight: 500;
  padding: 5px 15px;
  font-size: 18px;
  line-height: 1.5715;
}

.cartButtonContainer {
  display: block;
  justify-content: center;
  margin-top: 10px;
}

.payButton {
  width: 100%;
  margin: 10px 0;
}

.editButton {
  width: 100%;
  margin: 5px 0;
}
