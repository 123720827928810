// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.pageTitle {
  font-family: 'TT Norms', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  width: 100%;
  padding: 20px 0 30px 0;
  color: #6c7683;
  display: flex;
}

.boldLabel {
  font-family: 'TT Norms Bold', sans-serif;
  cursor: default;
}

.link {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.disabled {
  cursor: default;
}

@media screen and (max-width: 767px) {
  .pageTitle {
    padding: 25px;
  }
}
