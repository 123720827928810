.policyContainer {
  position: relative;
}

@media screen and (min-width: 992px) {
  .policyCell {
    flex: 2 1 50%;
  }
}

.mealContainer {
  display: flex;
}
