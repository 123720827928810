// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.root {
  @include font-defaults;
}

.actionsTitle {
  display: flex;
  justify-content: flex-end;
}

.activeLabel {
  color: $tbf-green;
  text-transform: uppercase;
}

.disabledLabel {
  color: $tbf-red;
  text-transform: uppercase;
}
