// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.root {
  @include font-defaults;
}

.total_wrapper {
  border: 1px solid #f0f0f0;
  margin: 20px 0;
}

.total {
  overflow: hidden;
  color: #666;
  padding: 20px;
}

.filter_dropdown_wrapper {
  padding: 20px 10px;
}

.filter_button {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 15px 10px;
}

.filter_dropdown_input {
  display: block;
  margin-bottom: 8px;
}

.search_input {
  margin-bottom: 20px;
}

.actions_link {
  padding: 0 20px;
  text-align: right;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  color: $tbf-blue;
  cursor: pointer;
  width: auto;
  float: right;
}

.action_link_text {
  cursor: pointer;
  margin-right: 5px;
}

.ellipses {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.copy_option {
  cursor: pointer;
  margin-left: 10px;
}
