// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;
}

.pdfExplanationButton {
  margin-bottom: 5px;
}
