@import 'variables.scss';
@import 'typography.scss';

.root {
  overflow-y: auto;
  background-color: #ffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;

  :global(.ant-select-item-group),
  :global(.ant-form-item-label > label) {
    text-transform: capitalize;
  }

  :global(.ant-select-selection-item-remove) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 991px) {
  .root {
    height: 90vh;
  }
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  text-decoration: underline;
  color: darkslategrey;
}

.upload_button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.map {
  padding: 20px 0;
}

.loading_details_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
