// Generated with util/create-component.js
@import '../../../variables.scss';
@import '../../../typography.scss';

.root {
  @include font-defaults;
  svg {
    vertical-align: unset;
  }
}

.loading_container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.select_all {
  margin-bottom: 15px;
  text-transform: unset;
}

.individualRate {
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 20px;
}

.fullWidth {
  width: 100%;
}

.alignRight {
  text-align: right;
}
