// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.root {
  @include font-defaults;
  background-color: $tbf-white;
  height: 100%;
}

.navbarContainer {
  height: 100%;
}

.logo {
  height: 100%;
  width: 150px;
  margin: 0 30px;
}

.poweredByLabel {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 30px;
  font-size: 14px;
  color: #afafaf;
}

@media screen and (max-width: 767px) {
  .poweredByLabel {
    display: none;
  }
}
