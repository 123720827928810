@import 'variables.scss';
@import 'typography.scss';

.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.loadingContainer {
  align-items: center;
  display: flex;
  justify-content: center;
}
