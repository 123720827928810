@import '../../variables.scss';
@import '../../typography.scss';

.root {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  text-align: center;
}

.coverImageContainer {
  width: 100%;
}

.coverImage {
  height: auto;
  max-height: 245px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  padding-top: 50px;
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding-bottom: 25px;
}

.reservationDetails {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.inputLabel {
  text-align: left;
  text-transform: inherit;
}

.backToStoreButton {
  width: 100%;
}

.useGiftCardButton {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    padding-top: 25px;
  }

  .confirmButton {
    width: 100%;
  }

  .backToStoreButton {
    width: 100%;
  }

  .useGiftCardButton {
    width: 100%;
  }
}
