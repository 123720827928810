// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.root {
  @include font-defaults;
}

.apiTokenWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  color: $tbf-black;
  width: 100%;

  .copyOption {
    cursor: pointer;
    margin-left: 10px;
    font-size: 18px;
  }

  .apiToken {
    word-break: break-all;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.noteContainer {
  padding: 10px 0;
  color: $tbf-red;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .saveButton {
    margin-left: 20px;
  }
}
