// Generated with util/create-component.js
@import 'variables.scss';
@import 'typography.scss';

.root {
  @include font-defaults;

  :global(.ant-form-item) {
    margin-bottom: 3px;
  }

  :global(.ant-select-item-group),
  :global(.ant-form-item-label > label) {
    text-transform: capitalize;
  }

  .submit_section {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    left: 0;
    background: $tbf-white;
    border-radius: 0 0 4px 4px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .submit_button {
    width: 100%;
  }

  .switch_button {
    margin-right: 5px;
  }

  .spinner_container {
    height: 100vh;
    display: flex;
    align-items: center;
  }

  .clearfix::after {
    content: '';
    display: table;
    clear: both;
  }

  .visible_button {
    float: right;
  }

  .detailsForm {
    overflow-y: scroll;
    height: 80vh;
  }
};
