
.root {
  overflow-y: auto;
  height: calc(100vh - 50px);
}

.sliderContainer {
  height: 540px;
  max-height: 50vh;
}

.floatingSearchContainer {
  position: relative;
}

.photoGalleryWrapper {
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .sliderContainer {
    height: 480px;
  }
}

@media screen and (max-width: 767px) {
  .sliderContainer {
    height: 340px;
  }
}

@media screen and (max-width: 575px) {
  .sliderContainer {
    height: 200px;
  }
}