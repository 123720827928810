
@import 'variables.scss';
@import 'typography.scss';

.root {
  @include font-defaults;
}

.tableContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 95%;
  margin: 30px auto;

  @media (min-width: 1441px) {
    width: 80%;
  }
}

.bookingsListTable {
  max-width: 98%;
  text-align: center;
}
