@import 'variables.scss';
@import 'typography.scss';

.root {
  padding: 20px;
  height: calc(100vh - 70px);
}
@media screen and (max-width: 756px) {
  .root {
    overflow: auto;
  }
}

@media screen and (min-width: 991px) {
  .root {
    width: 80%;
    margin: 0 auto;
  }
}
