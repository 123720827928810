@import 'variables.scss';
@import 'typography.scss';

.root {
  align-items: center;
  display: flex;
  justify-content: space-between;
  color: $tbf-black;
  font-size: 16px;
  height: 100px;
}

.multiPropertyContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.menuContainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.logoContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cartLngContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cartQuantity {
  border-radius: 50%;
  background-color: $tbf-black;
  padding: 5px;
  color: $tbf-white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

.cartInfo {
  align-items: center;
  display: flex;
  cursor: pointer;
  width: 200px;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  border-left: 2px solid $tbf-lightGrey;
  border-right: 2px solid $tbf-lightGrey;
  margin: 0 20px;
}

.cartIcon {
  font-size: 18px;
}

.cartTitle {
  margin-right: 5px;
  font-weight: 700;
}

.cartTitle:hover {
  color: #ababab;
}

.logo {
  width: 200px;
  height: 70px;
  margin-right: 20px;
  cursor: pointer;
  object-fit: contain;
  object-position: left;
}

.useGiftCardButton {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
  border: 1px solid;
}

@media screen and (max-width: 767px) {
  .root {
    padding: 0 25px;
  }

  .logo {
    width: 45px;
    height: 45px;
  }

  .cartInfo {
    width: 50px;
    padding: 0 10px;
  }

  .cartIcon {
    font-size: 28px;
  }
}
