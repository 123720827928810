// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.textContainer {
  padding-left: 50px;
}

.giftCardDetailsContainer {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 20px 0;

  :first-child {
    margin-right: 20px;
  }
}

.giftCardDetails {
  display: flex;
  align-items: center;
  :first-child {
    margin-right: 5px;
  }
}

.selectDatesLabel {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  font-feature-settings: 'liga' off;
  margin-top: 30px;
}

.noAvailabilityWrapper {
  max-width: 1200px;
  margin: 40px auto;
}

.noAvailibility {
  font-size: large;
}

.differentDate {
  font-size: medium;
  align-self: center;
}

.wrapper {
  position: absolute;
  height: 100%;
  width: 95vw;
}

.propertyInfoContainer {
  padding: 10px;
}

.total {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.2px;

  .totalPrice {
    font-size: 24px;
  }
}

@media screen and (max-width: 767px) {
  .textContainer {
    padding-left: 0;
  }

  .wrapper {
    position: relative;
  }

  .propertyInfoContainer {
    padding-top: 40px;
  }

  .total {
    font-size: 16px;

    .totalPrice {
      font-size: 18px;
    }
  }
}
