// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.root {
  @include font-defaults;

  :global(.ant-upload-list-picture-card) {
    width: 100%;
  }

  :global(.ant-upload-list-picture-card-container) {
    width: 32%;
    height: 200px;
    text-align: center;
    justify-content: center;
    display: flex;
    position: relative;
    max-width: 250px;
    min-width: 200px;
  }

  :global(.ant-upload-list-picture-card) {
    ::before {
      width: 100%;
    }
  }

  :global(.ant-upload.ant-upload-select.ant-upload-select-picture-card) {
    width: 32%;
    min-height: 170px;
    text-align: center;
    justify-content: center;
    display: flex;
    max-width: 250px;
    min-width: 200px;
  }

  :global(.ant-upload-list.ant-upload-list-picture-card) {
    display: flex;
    flex-wrap: wrap;
  }
}

.upload_button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.photo_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 10px;
  cursor: pointer;
}

.property_photo_container {
  width: 100%;
  position: relative;
}

.property_photo_img {
  width: 100%;
  height: 170px;
}

.delete_photo_button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: $tbf-white;
  color: $tbf-darkerGrey;
  font-size: 15px;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.add_photo {
  font-size: 30px;
}

.add_new_photo {
  color: $tbf-blue;
}

.photo_description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.edit_photo_details {
  float: right;
}