@import "variables.scss";

$breakpoint: 640px;

.wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
}


.inner {
  align-items: center;
  display: flex;
  min-height: 50px;
}

.range_picker {
  margin-bottom: 0;
  height: 54px;
  border-radius: 28px 0px 0px 28px;
  padding-left: 1.5em;
  input, input::placeholder {
    color: $tbf-darkGrey;
  }
}

.twocols {
  display: flex;
}

.title {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}


.rangePicker {
  margin-bottom: 0;
  height: 100%;
}

.occupancyDropDown {
  margin-bottom: 0;

  :global {
    .dropdown {
      height: 100%;
    }
    .dropdown-toggle {
      height: 100%;
      border: none;
      border-radius: 0;
      padding-right: 16px;
    }
    .btn-primary:not(:disabled):not(.disabled):focus {
      border: none;
    }
    .btn-primary.dropdown-toggle {
      width: 195px;
    }
    .show > .btn-primary.dropdown-toggle {
      border: none;
    }
    .dropdown-menu.show {
      width: 305px;
      max-height: 535px;
      overflow: auto;
      z-index: 999;
    }
  }
}

@media (max-width: $breakpoint) {
  .wrapper {
    padding: 0px;
  }

  .title {
    display: none;
  }

  .inner {
      display: block;
      width: 100%;

      .occupancyDropDown :global .btn-primary.dropdown-toggle {
        width: 70%;

      }

      .twocols {
        display: flex;
        justify-content: space-between;
        padding: 0px 10px;
      }
  }
}
