@import 'variables.scss';
@import 'typography.scss';

$header_height: 80px;

.root {
  @include font-defaults;
}

body {
  overflow: hidden;
}

.root {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.giftCardStoreContent {
  overflow-y: auto;
  height: calc(100vh - $header_height);
  margin-top: $header_height;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

header {
  position: fixed;
  height: $header_height;
  top: 0px;
  width: 100vw;
  z-index: 10;
  & > nav {
    box-shadow: none !important;
  }
}
