// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;
}

.checkIcon {
  color: $tbf-green;
  font-weight: 500;
  font-size: 20px;
}

.warningIcon {
  color: red;
}
