// Generated with util/create-component.js
@import "../../../../variables.scss";
@import "../../../../typography.scss";

.root {
  @include font-defaults;
}

.statusLabel {
  text-transform: capitalize;
  text-decoration: underline;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 150px;
}

.search_input_container {
  margin-bottom: 20px;
}
