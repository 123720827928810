// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.root {
  background-color: #f1f5f7;
  min-height: 100%;
  min-width: 100%;
  font-family: 'TT Norms';
}

.title {
  font-size: 72px;
  margin: 50px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  text-align: center;
}

.propertiesList {
  margin: 0 20px;
  padding: 20px 0;
}

@media screen and (max-width: 991px) {
  .propertiesList {
    margin: 0 10px;
  }

  .title {
    font-size: 48px;
  }
}

.loadingContainer {
  align-items: center;
  display: flex;
  justify-content: center;
}
