// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  text-decoration: underline;
  color: darkslategrey;
}

.createButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $tbf-blue;
  cursor: pointer;
}
