// Generated with util/create-component.js
@import '../../../variables.scss';
@import '../../../typography.scss';

.label {
  text-align: right;
  font-weight: 500;
}

.card_type {
  text-transform: uppercase;
}
