@import 'variables.scss';
@import 'typography.scss';

$header_height: 100px;

body {
  overflow: hidden;
}

.root {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.giftCardStoreContent {
  overflow-y: auto;
  height: calc(100vh - $header_height);
  margin-top: $header_height;
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 991px) {
  .giftCardStoreContent > div {
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 992px) {
  .giftCardStoreContent > div {
    padding-bottom: 20px;
  }
}

header {
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 10;
  height: auto;
  & > nav {
    box-shadow: none !important;
  }
}

@media screen and (min-width: 768px) {
  .giftCardStoreContent,
  .root,
  header {
    padding: 0 70px;
  }
}

@media screen and (min-width: 1700px) {
  .giftCardStoreContent,
  .root,
  header {
    padding: 0 15%;
  }
}

@media screen and (min-width: 2300px) {
  .giftCardStoreContent,
  .root,
  header {
    padding: 0 20%;
  }
}
