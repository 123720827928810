// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.itemTitle {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 18px;
}

.itemPrice {
  font-size: 14px;
  font-weight: bold;
}

.itemRow {
  justify-content: space-between;
  line-height: 1.5715;
  padding: 10px 5px;
  color: $tbf-black;
}

.itemPhoto {
  > img {
    width: 120px;
    height: 80px;
    object-fit: cover;
    object-position: center;
  }
}

.textColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.discountPercentage {
  font-size: 12px !important;
  line-height: 16px !important;
}

.cartItemsWrapper {
  > div {
    border-bottom: 1px solid $tbf-lightGrey;
    padding-bottom: 25px;
    padding-top: 25px;
  }
  > div:last-child {
    border-bottom: none;
  }
}
