// Generated with util/create-component.js
@import 'variables.scss';
@import 'typography.scss';

.root {
  @include font-defaults;
  height: calc(100vh - 50px);
}

@media screen and (max-width: 756px) {
  .root {
    overflow: auto;
  }
}

.tab_root {
  width: 95%;
  margin: 30px auto;

  @media (min-width: 1441px) {
    width: 80%;
  }
}
