// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.root {
  @include font-defaults;
  width: 350px;
  
  :global(.ant-form-item-label > label) {
    text-transform: capitalize;
  }
}

.image_details {
  cursor: pointer;
}

.kind_label {
  text-transform: capitalize;
}

.submit_button {
  margin-left: 20px;
}

.footer {
  align-items: center;
  background: $tbf-white;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
  display: flex;
  height: 60px;
  justify-content: flex-end;
  padding-top: 20px;
  text-align: right;
  width: 100%;
}


