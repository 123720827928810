// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

$breakpoint: 640px;

.root {
  @include font-defaults;
}

:global(.ant-select-item-option-grouped) {
  padding-left: 10px !important;
}

.loadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
}

.option_icon {
  padding-right: 15px;
}

.option_info {
  font-size: 10px;
}

.option_wrapper {
  display: flex;
  align-items: center;
  height: auto;
  line-height: 1 !important;
}

.place_dropdown {
  min-width: 200px;
  height: 100%;

  :global {
    .dropdown-toggle {
      border-radius: 28px 0 0 28px;
    }
  }

  :global(.ant-select-selector) {
    height: 54px !important;
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  @media screen and (min-width: 971px) {
    :global(.ant-select-selector) {
      border-radius: 28px 0 0 28px !important;
    }
  }

  :global(.ant-select-selection-item) {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  :global(.ant-select-selection-search) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: $breakpoint) {
  .root {
    margin-bottom: 10px;
  }

  .place_dropdown {
    width: 100%;
  }

  :global(.ant-select-selector) {
    border-radius: 0px !important;
  }
}
