// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;
  padding: 10px;
  background-color: #fafafa;
}

.search_input_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}

.search_input_addon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.search_input_dropdown {
  position: absolute;
  z-index: 100;
  background: #fafafa;
  left: 0;
  right: 0;
  border-bottom: 1px solid #f0f0f0;
  background: #fafafa;
  padding: 10px;
}

.search_label {
  width: 160px;
  margin-right: 5px;
  text-align: right;
}

.filters_wrapper {
  padding: 10px 0;
}

.dateRangeContainer {
  display: flex;
  align-items: center;
}

.reset_button {
  margin: 0 10px;
}

.active_filter {
  color: $tbf-blue;
}

.selectContainer {
  width: 100%;
  text-align: left;
}

.bookingDateContainer {
  @extend .dateRangeContainer;
  margin-top: 10px;
}