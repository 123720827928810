// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.root {
  @include font-defaults;
  display: flex;
  height: calc(100vh - 50px);
}

@media screen and (max-width: 756px) {
  .root {
    overflow: auto;
  }
}

.settings_container {
  width: 60%;
  margin: 30px 0;
}

.loading_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.property_settings_container {
  width: 100%;
  margin-top: 30px;
}

.details_container {
  padding: 20px;
}
