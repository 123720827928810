// Generated with util/create-component.js
@import '../../../variables.scss';
@import '../../../typography.scss';

.label {
  text-align: right;
  font-weight: 500;
}


.calendar_outlined {
  margin-right: 5px;
}

.room_title {
  font-weight: bold;
}

.room_title_footer {
  display: flex;
  align-items: center;
  font-size: 12px;
}
