// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;
  background: transparent;
}

.inventory_container {
  display: flex;
  height: calc(100vh - 50px);
  width: 95%;
  padding-top: 20px;
  margin: 0 auto;
  border: none;
  
  @media (min-width: 1441px) {
    width: 80%;
  }
}
