@import "variables.scss";
@import "typography.scss";

.button {
  display: flex;
  width: 100%;
  height: 40px;
  padding-right: 8px;
  padding-left: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: $tbf-blue;
  transition: all 200ms ease;
  border: none;
  letter-spacing: 1.17px;
}

.button:global.btn-primary:active,
.button:global.btn-primary:hover,
.button:global.btn-primary:not(:disabled):not(.disabled):active
{
  background-color: $tbf-blue;
  outline: 0;
  box-shadow: none;
}

.button:global.btn-primary:focus {
  box-shadow: none;
}

.button:global.btn-primary:disabled {
  background-color: $tbf-blue;
  opacity: 1;
}

.buttonCaption {
  margin-right: 4px;
}

@media screen and (max-width: 991px) {
  .button {
    height: 48px;
  }
}

.spinner {
  width: 14px;
  height: 14px;
  margin-left: -18px;
  margin-right: 4px;
}