.dropdownToggle {
  display: flex;
  width: 100%;
  height: 40px;
  padding-right: 8px;
  padding-left: 8px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(0,0,0,.25);
  border-radius: 2px;
  transition: all 200ms ease;
  background-color: white;
  color: var(--main-text-color);
}

.hiddenSelect {
  visibility: hidden;
  display: none;
}


.dropdownButton {
  border: none;
}

.dropdownToggle,
.dropdownButton {
  background-color: white;
  color: var(--main-text-color);
}

.dropdownToggle:hover,
.dropdownToggle:focus,
.dropdownButton:hover,
.dropdownButton:focus {
  background-color: white;
  color: var(--main-text-color);
  outline: none;
  box-shadow: none;
}

.dropdown:global>.btn-primary.dropdown-toggle,
.dropdownToggle:global.btn-primary:not(:disabled):not(.disabled):focus,
.dropdownToggle:global.btn-primary:not(:disabled):not(.disabled):active,
.dropdownButton:global.btn-primary:not(:disabled):not(.disabled):focus,
.dropdownButton:global.btn-primary:not(:disabled):not(.disabled):active {
  background-color: white;
  color: var(--main-text-color);
  box-shadow: none;
  outline: none;
  border-color: rgba(0,0,0,.25);
}

.dropdown:global.show>.btn-primary.dropdown-toggle,
.dropdownToggle:global.btn-primary:not(:disabled):not(.disabled):focus {
  border-bottom: 2px solid #008489;
  margin-bottom: -1px;
}


.containerHorizontal,
.containerVertical {
  display: flex;
  align-items: center;
}

.containerVertical {
  flex-direction: column;
}

.dropdown, .dropdownToggle {
  width: 100%;
}

.dropdown>.dropdownToggleInvalid:global.btn-primary.dropdown-toggle,
.dropdownToggleInvalid:global.btn-primary:not(:disabled):not(.disabled):focus,
.dropdownToggleInvalid:global.btn-primary:not(:disabled):not(.disabled):active,
.dropdownToggleInvalid:global.btn-primary:not(:disabled):not(.disabled):focus,
.dropdownToggleInvalid:global.btn-primary:not(:disabled):not(.disabled):active {
  border-color: #dc3545;
}

.dropdownToggle:empty:after {
  margin-right: 0;
  margin-left: auto;
}