@import 'variables.scss';
@import 'typography.scss';

.root {
  width: 100%;
}

.header {
  display: flex;
  justify-content: flex-start;
  margin: 10px auto 20px auto;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}

.tableHeader {
  background-color: $tbf-lightGrey;
  padding: 10px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  font-feature-settings: 'liga' off;
  font-weight: 700;
}

.textRight {
  text-align: right;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
}

.quantityContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.itemRow {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dbdbdb;
  padding: 30px 0;
  line-height: 1.5715;
  font-size: 20px;
  align-items: center;
}

.submitButton {
  display: flex;
  margin: 40px 0px auto auto;
  width: 150px;
  justify-content: center;
}

.photo {
  width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: center;
}

.info {
  padding: 0 15px;
}

.name {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}

.description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  font-feature-settings: 'liga' off;
  color: #303a47;
}

.priceDetails {
  text-align: right;
  padding-right: 10px;
}

.totalLabel {
  text-align: right;
  padding-right: 20px;
}

.quantityLabel {
  text-align: center;
}

.quantity {
  width: 130px;
  display: flex;
  justify-content: space-between;

  .quantityIterator {
    border-color: $tbf-lightGrey;
    background-color: $tbf-lightGrey;
  }

  .quantityInput {
    width: 15%;
    height: 100%;
    text-align: center;
    padding: 0;
    font-size: 20px;
    pointer-events: none;
  }
}

.priceContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .price {
    font-weight: 700;
  }
}

.summary {
  background-color: $tbf-lightGrey;
  padding: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;

  .totalAmount {
    text-align: right;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  }
}

.emptyCartContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .emptyCartTitle {
    font-size: 18px;
    margin: 50px 0 30px 0;
    line-height: 30px;
  }
}

@media screen and (max-width: 767px) {
  .root {
    padding: 25px;
  }

  .header {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
  }

  .submitButton {
    width: 100%;
    margin: 20px 0px auto auto;
  }

  .photo {
    width: 120px;
  }

  .quantity {
    justify-content: space-between;
    width: 100%;
  }

  .price {
    font-size: 16px;
  }

  .summary {
    font-size: 16px;

    .totalAmount {
      font-size: 20px;
    }
  }
}
