@import "variables.scss";
@import "typography.scss";

@media screen and (max-width: 767px) {
  .guest_info_wrapper {
    border-bottom: 1px solid $tbf-lightGrey;
    margin-bottom: 10px;
  }
}

.guest_info {
  :global(label) {
    color: $tbf-grey;
    font-size: 11px;
    font-weight: 400;
    height: 15px;
    opacity: 0.75;
    text-transform: uppercase;
  }
}

.button_container {
  align-items: center;
  display: flex;
}

.deleteIcon {
  font-size: 20px;
}