/* Variables */
$tbf-lightBlue: #5e8bfc;
$tbf-blue: #1890ff;
$tbf-white: #ffffff;
$tbf-grey: #333333de;
$tbf-darkerGrey: #00000099;
$tbf-black:#0F1015;
$tbf-red: #ff0000;
$tbf-darkGrey: #595959;
$tbf-lightGrey: #F1F5F7;
$tbf-green: #5dcf49;
$tbf-logoBlue: #91c3fa;