// Generated with util/create-component.js
@import '../../../../variables.scss';
@import '../../../../typography.scss';

.root {
  @include font-defaults;
}

.actions_link {
  padding: 0 20px;
  text-align: right;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  color: $tbf-blue;
  cursor: pointer;
  width: auto;
  float: right;
}

.action_link_text {
  cursor: pointer;
  margin-right: 5px;
}

.create_button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $tbf-blue;
  cursor: pointer;
  float: right;
}

.header_container {
  margin-bottom: 20px;
}
