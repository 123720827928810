// Generated with util/create-component.js
@import 'variables.scss';
@import 'typography.scss';
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600&display=swap');

.giftCardUnitSection {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  justify-content: flex-start;
  margin: 40px auto;
}

.loadingContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 150px;
}

.roomTypeOptionHeader {
  max-width: 870px;
  font-size: 35px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.roomTypeTable {
  max-width: 820px;
  max-width: 790px;
  font-size: 16px;
  margin-bottom: 40px;
}

.roomTypeHeaderColumn {
  font-size: 14px;
  background-color: rgb(252, 252, 252);
  border-bottom: 1px solid var(--border-color);
  color: rgb(114, 114, 114);
  font-family: Roboto, sans-serif;
}

.roomTypePhoto {
  height: 80px;
  width: 80px;
  object-fit: cover;
  object-position: center;
}

.roomTypeInfo {
  color: rgb(51, 51, 51);
}

.roomTypeTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.2px;
  font-feature-settings: 'liga' off;

  color: #000000;
}

.roomTypeDescription {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  font-feature-settings: 'liga' off;
}

.roomTypeNights {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.2px;
  font-feature-settings: 'liga' off;
  text-transform: lowercase;
}

.roomTypeOccupancy {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.selectWrapper {
  display: flex;
  justify-content: flex-end;
}

.bookButton {
  margin-right: 20px;
  padding-bottom: 20px;
}

.bookBtn {
  font-family: Roboto, sans-serif;
  background-color: rgb(74, 74, 74);
  border-color: rgb(74, 74, 74);
  columns: rgb(74, 74, 74);
  width: 100px;
  color: rgb(243, 243, 243);
}

.bookBtn:hover {
  background-color: rgb(134, 134, 134);
  border-color: rgb(134, 134, 134);
  color: rgb(167, 167, 167);
}

.occupancyCaption {
  padding-right: 10px;
}

.selectButton {
  :global(.ant-radio-inner) {
    height: 30px;
    width: 30px;
    border-color: $tbf-black;
  }

  :global(.ant-radio-inner::after) {
    background-color: $tbf-black;
  }
}

@media screen and (max-width: 767px) {
  .roomTypeTitleContainer {
    padding-bottom: 25px;
  }
}
