// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.root {
  @include font-defaults;
  background: url('~static/main-bg.svg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  min-height: 100%;
  height: auto;
}

.loadingContainer {
  align-items: center;
  display: flex;
  justify-content: center;
}

.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 0 10px 0;
  width: 70%;

  p {
    font-size: 68px;
    font-weight: 600;
    color: $tbf-black;
    text-align: center;
    line-height: 1.2;
    white-space: pre-line;
  }

  .giftCampaignDetails {
    font-size: 68px;
    font-weight: 600;
    color: $tbf-black;
    text-align: center;
    line-height: 1.2;
    white-space: pre-line;
  }

  .giftCampaignDescription {
    @extend .giftCampaignDetails;
    font-size: 45px;
  }
}

.descriptionContainer {
  width: 60%;
  font-size: 24px;
  font-weight: 200;
  padding-top: 20px;
  color: $tbf-black;
  text-align: center;
  line-height: 1.2;
}

.giftCampaignDetails {
  min-width: 90%;
}

.giftCampaignContainer {
  display: flex;
  align-items: center;
  width: 60%;
  flex-direction: column;
  height: fit-content;
}

.giftCampaignOptionsList {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: transparent;
  justify-content: center;
  padding: 20px 0;
}

.giftCampaignOptionContainer {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  background-color: $tbf-white;
  padding: 50px 50px 20px 50px;
  border-radius: 20px;
  height: max-content;
}

@media screen and (max-width: 767px) {
  .root {
    padding-bottom: 100px;
  }

  .giftCampaignOptionContainer {
    padding: 30px;
  }

  .giftCampaignContainer {
    width: 90%;
  }

  .titleContainer {
    width: 100%;

    p {
      font-size: 35px;
    }

    .giftCampaignDetails {
      font-size: 35px;
    }

    .giftCampaignDescription {
      font-size: 18px;
    }
  }
}
