@import 'variables.scss';
@import 'typography.scss';
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600&display=swap');

.root {
  background-color: $tbf-lightGrey;
  padding: 30px 40px;

  :global(.ant-result) {
    padding: 48px 32px 25px 32px;
  }

  :global(.anticon) {
    font-size: 56px;
  }
}

.headerContent {
  height: 100%;
}

.header {
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  background: #d8dce1;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 0 10px;
  min-height: 24px;
  gap: 8px;
  margin-bottom: 10px;
}

.mobileHeader {
  @extend .header;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.title {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 22px;
}

.price {
  margin-bottom: 30px;
  font-size: 20px;
}

.titleConfirmation {
  color: rgb(5, 5, 5);
  font-size: 30px;
  margin-bottom: 20px;
}

.subTitleConfirmation {
  color: rgb(109, 109, 109);
  font-size: 22px;
  margin-bottom: 25px;
}

.storeButton {
  margin-top: 10px;
  border: 2px solid;
  font-weight: 700;
}

.buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orderId {
  margin-bottom: 25px;
}

.resultTitle {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}

.resultSubtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.orderSummary {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 25px;
}

.alignCenter {
  display: flex;
  align-items: center;
}

.orderRoom {
  padding: 0 10px 20px 10px;

  .photo {
    height: 40px;
    width: 40px;
    object-fit: cover;
    object-position: center;
  }

  .roomTitle {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
  }

  .roomPrice {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  .roomDetails {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  .giftCertificateNumber {
    @extend .roomDetails;
    font-weight: 700;
  }
}

.roomDetailsWrapper {
  margin: 5px 0;

  .roomDetailsLabel {
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;

    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .roomDetails {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
}

@media screen and (max-width: 767px) {
  .root {
    padding: 24px 16px;
    margin: 25px;

    :global(.ant-result) {
      padding: 20px;
    }
  }

  .orderRoom {
    .roomTitle {
      font-size: 14px;
      line-height: 20px;
    }

    .roomPrice {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .resultTitle {
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 40px;
  }

  .resultSubtitle {
    font-size: 14px;
    line-height: 20px;
  }
}
