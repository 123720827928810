$font-family: 'Avenir Next', Helvetica, Arial, sans-serif;
$small-font-size: 12px;
$medium-font-size: 18px;
$large-font-size: 36px;

@mixin font-defaults {
  font-family: $font-family;
}

@mixin heading {
  @include font-defaults;

  font-size: 40px;
  font-weight: bold;
}

@font-face {
  font-family: 'TT Norms Bold';
  src: local('TT Norms Bold'), url('./fonts/TTNorms/TTNorms-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'TT Norms';
  src: local('TT Norms'), url('./fonts/TTNorms/TTNorms-Regular.ttf') format('truetype');
}

// GothamRounded
@font-face {
  font-family: 'Gotham Rounded Book';
  src: local('Gotham Rounded'), url('./fonts/GothamRounded/GothamRoundedBook.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham Rounded Bold';
  src: local('Gotham Rounded Bold'), url('./fonts/GothamRounded/GothamRoundedBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham Rounded Light';
  src: local('Gotham Rounded Light'), url('./fonts/GothamRounded/GothamRoundedLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham Rounded Medium';
  src: local('Gotham Rounded Medium'), url('./fonts/GothamRounded/GothamRoundedMedium.ttf') format('truetype');
}

// Gotham
@font-face {
  font-family: 'Gotham';
  src: local('Gotham'), url('./fonts/Gotham/GothamBook.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham Bold';
  src: local('Gotham Bold'), url('./fonts/Gotham/GothamBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham Bold Italic';
  src: local('Gotham Bold Italic'), url('./fonts/Gotham/GothamBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham Book Italic';
  src: local('Gotham Book Italic'), url('./fonts/Gotham/GothamBookItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham Light';
  src: local('Gotham Light'), url('./fonts/Gotham/GothamLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham Light Italic';
  src: local('Gotham Light Italic'), url('./fonts/Gotham/GothamLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham Medium';
  src: local('Gotham Medium'), url('./fonts/Gotham/GothamMedium.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham Medium Italic';
  src: local('Gotham Medium Italic'), url('./fonts/Gotham/GothamMediumItalic.ttf') format('truetype');
}

// BebasNeue
@font-face {
  font-family: 'Bebas Neue';
  src: local('Bebas Neue'), url('./fonts/BebasNeue/BebasNeue-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Bebas Neue Bold';
  src: local('Bebas Neue Bold'), url('./fonts/BebasNeue/BebasNeue-Bold.ttf') format('truetype');
}

// Abril Fatface
@font-face {
  font-family: 'Abril Fatface';
  src: local('Abril Fatface'), url('./fonts/Abril_Fatface/AbrilFatface-Regular.ttf') format('truetype');
}

// Alegreya
@font-face {
  font-family: 'Alegreya';
  src: local('Alegreya'), url('./fonts/Alegreya/static/Alegreya-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Alegreya Italic';
  src: local('Alegreya Italic'), url('./fonts/Alegreya/static/Alegreya-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Alegreya Medium';
  src: local('Alegreya Medium'), url('./fonts/Alegreya/static/Alegreya-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Alegreya Medium Italic';
  src: local('Alegreya Medium Italic'), url('./fonts/Alegreya/static/Alegreya-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Alegreya SemiBold';
  src: local('Alegreya SemiBold'), url('./fonts/Alegreya/static/Alegreya-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Alegreya SemiBold Italic';
  src: local('Alegreya SemiBold Italic'), url('./fonts/Alegreya/static/Alegreya-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Alegreya Bold';
  src: local('Alegreya Bold'), url('./fonts/Alegreya/static/Alegreya-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Alegreya Bold Italic';
  src: local('Alegreya Bold Italic'), url('./fonts/Alegreya/static/Alegreya-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Alegreya ExtraBold';
  src: local('Alegreya ExtraBold'), url('./fonts/Alegreya/static/Alegreya-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Alegreya ExtraBold Italic';
  src: local('Alegreya ExtraBold Italic'),
    url('./fonts/Alegreya/static/Alegreya-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Alegreya Black';
  src: local('Alegreya Black'), url('./fonts/Alegreya/static/Alegreya-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Alegreya Black Italic';
  src: local('Alegreya Black Italic'), url('./fonts/Alegreya/static/Alegreya-BlackItalic.ttf') format('truetype');
}

// Alfa Slab One
@font-face {
  font-family: 'Alfa Slab One';
  src: local('Alfa Slab One'), url('./fonts/Alfa_Slab_One/AlfaSlabOne-Regular.ttf') format('truetype');
}

// Comfortaa
@font-face {
  font-family: 'Comfortaa';
  src: local('Comfortaa'), url('./fonts/Comfortaa/Comfortaa-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Comfortaa Light';
  src: local('Comfortaa Light'), url('./fonts/Comfortaa/static/Comfortaa-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Comfortaa Medium';
  src: local('Comfortaa Medium'), url('./fonts/Comfortaa/static/Comfortaa-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Comfortaa SemiBold';
  src: local('Comfortaa SemiBold'), url('./fonts/Comfortaa/static/Comfortaa-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Comfortaa Bold';
  src: local('Comfortaa Bold'), url('./fonts/Comfortaa/static/Comfortaa-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  src: local('Lato'), url('./fonts/Lato/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Italic';
  src: local('Lato Italic'), url('./fonts/Lato/Lato-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Thin';
  src: local('Lato Thin'), url('./fonts/Lato/Lato-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Thin Italic';
  src: local('Lato Thin Italic'), url('./fonts/Lato/Lato-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Light';
  src: local('Lato Light'), url('./fonts/Lato/Lato-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Light Italic';
  src: local('Lato Light Italic'), url('./fonts/Lato/Lato-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Bold';
  src: local('Lato Bold'), url('./fonts/Lato/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Bold Italic';
  src: local('Lato Bold Italic'), url('./fonts/Lato/Lato-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Black';
  src: local('Lato Black'), url('./fonts/Lato/Lato-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Black Italic';
  src: local('Lato Black Italic'), url('./fonts/Lato/Lato-BlackItalic.ttf') format('truetype');
}

// Lobster
@font-face {
  font-family: 'Lobster';
  src: local('Lobster'), url('./fonts/Lobster/Lobster-Regular.ttf') format('truetype');
}

// Lora
@font-face {
  font-family: 'Lora';
  src: local('Lora'), url('./fonts/Lora/static/Lora-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lora Italic';
  src: local('Lora Italic'), url('./fonts/Lora/static/Lora-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lora Medium';
  src: local('Lora Medium'), url('./fonts/Lora/static/Lora-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Lora Medium';
  src: local('Lora Medium'), url('./fonts/Lora/static/Lora-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Lora Medium Italic';
  src: local('Lora Medium Italic'), url('./fonts/Lora/static/Lora-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lora SemiBold';
  src: local('Lora SemiBold'), url('./fonts/Lora/static/Lora-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lora SemiBold Italic';
  src: local('Lora SemiBold Italic'), url('./fonts/Lora/static/Lora-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lora SemiBold';
  src: local('Lora SemiBold'), url('./fonts/Lora/static/Lora-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lora Bold';
  src: local('Lora Bold'), url('./fonts/Lora/static/Lora-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lora Bold Italic';
  src: local('Lora Bold Italic'), url('./fonts/Lora/static/Lora-BoldItalic.ttf') format('truetype');
}

// Merriweather
@font-face {
  font-family: 'Merriweather';
  src: local('Merriweather'), url('./fonts/Merriweather/Merriweather-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather Italic';
  src: local('Merriweather Italic'), url('./fonts/Merriweather/Merriweather-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather Light Italic';
  src: local('Merriweather Light Italic'), url('./fonts/Merriweather/Merriweather-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather Bold';
  src: local('Merriweather Bold'), url('./fonts/Merriweather/Merriweather-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather Bold Italic';
  src: local('Merriweather Bold Italic'), url('./fonts/Merriweather/Merriweather-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather Black';
  src: local('Merriweather Black'), url('./fonts/Merriweather/Merriweather-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather Black Italic';
  src: local('Merriweather Black Italic'), url('./fonts/Merriweather/Merriweather-BlackItalic.ttf') format('truetype');
}

// Montserrat
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url('./fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Italic';
  src: local('Montserrat Italic'), url('./fonts/Montserrat/static/Montserrat-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Thin';
  src: local('Montserrat Thin'), url('./fonts/Montserrat/static/Montserrat-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Thin Italic';
  src: local('Montserrat Thin Italic'), url('./fonts/Montserrat/static/Montserrat-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat ExtraLight';
  src: local('Montserrat ExtraLight'), url('./fonts/Montserrat/static/Montserrat-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat ExtraLight Italic';
  src: local('Montserrat ExtraLight Italic'),
    url('./fonts/Montserrat/static/Montserrat-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Light';
  src: local('Montserrat Light'), url('./fonts/Montserrat/static/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Light Italic';
  src: local('Montserrat Light Italic'), url('./fonts/Montserrat/static/Montserrat-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Medium';
  src: local('Montserrat Medium'), url('./fonts/Montserrat/static/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Medium Italic';
  src: local('Montserrat Medium Italic'),
    url('./fonts/Montserrat/static/Montserrat-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat SemiBold';
  src: local('Montserrat SemiBold'), url('./fonts/Montserrat/static/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat SemiBold Italic';
  src: local('Montserrat SemiBold Italic'),
    url('./fonts/Montserrat/static/Montserrat-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Bold';
  src: local('Montserrat Bold'), url('./fonts/Montserrat/static/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Bold Italic';
  src: local('Montserrat Bold Italic'), url('./fonts/Montserrat/static/Montserrat-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat ExtraBold';
  src: local('Montserrat ExtraBold'), url('./fonts/Montserrat/static/Montserrat-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat ExtraBold Italic';
  src: local('Montserrat ExtraBold Italic'),
    url('./fonts/Montserrat/static/Montserrat-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Black';
  src: local('Montserrat Black'), url('./fonts/Montserrat/static/Montserrat-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Black Italic';
  src: local('Montserrat Black Italic'), url('./fonts/Montserrat/static/Montserrat-BlackItalic.ttf') format('truetype');
}

// Open Sans
@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url('./fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans Italic';
  src: local('Open Sans Italic'), url('./fonts/Open_Sans/static/OpenSans/OpenSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans Light';
  src: local('Open Sans Light'), url('./fonts/Open_Sans/static/OpenSans/OpenSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans Light Italic';
  src: local('Open Sans Light Italic'),
    url('./fonts/Open_Sans/static/OpenSans/OpenSans-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans Medium';
  src: local('Open Sans Medium'), url('./fonts/Open_Sans/static/OpenSans/OpenSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans Medium Italic';
  src: local('Open Sans Medium Italic'),
    url('./fonts/Open_Sans/static/OpenSans/OpenSans-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans Bold';
  src: local('Open Sans Bold'), url('./fonts/Open_Sans/static/OpenSans/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans Bold Italic';
  src: local('Open Sans Bold Italic'),
    url('./fonts/Open_Sans/static/OpenSans/OpenSans-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans SemiBold';
  src: local('Open Sans SemiBold'), url('./fonts/Open_Sans/static/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans SemiBold Italic';
  src: local('Open Sans SemiBold Italic'),
    url('./fonts/Open_Sans/static/OpenSans/OpenSans-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans ExtraBold';
  src: local('Open Sans ExtraBold'), url('./fonts/Open_Sans/static/OpenSans/OpenSans-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans ExtraBold Italic';
  src: local('Open Sans ExtraBold Italic'),
    url('./fonts/Open_Sans/static/OpenSans/OpenSans-ExtraBoldItalic.ttf') format('truetype');
}

//Oswald
@font-face {
  font-family: 'Oswald';
  src: local('Oswald'), url('./fonts/Oswald/static/Oswald-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Oswald ExtraLight';
  src: local('Oswald ExtraLight'), url('./fonts/Oswald/static/Oswald-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Oswald Light';
  src: local('Oswald Light'), url('./fonts/Oswald/static/Oswald-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Oswald Medium';
  src: local('Oswald Medium'), url('./fonts/Oswald/static/Oswald-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Oswald SemiBold';
  src: local('Oswald SemiBold'), url('./fonts/Oswald/static/Oswald-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Oswald Bold';
  src: local('Oswald Bold'), url('./fonts/Oswald/static/Oswald-Bold.ttf') format('truetype');
}

// PT Serif
@font-face {
  font-family: 'PT Serif';
  src: local('PT Serif '), url('./fonts/PT_Serif/PTSerif-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'PT Serif Italic';
  src: local('PT Serif Italic'), url('./fonts/PT_Serif/PTSerif-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'PT Serif Bold';
  src: local('PT Serif Bold'), url('./fonts/PT_Serif/PTSerif-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'PT Serif Bold Italic';
  src: local('PT Serif Bold Italic'), url('./fonts/PT_Serif/PTSerif-BoldItalic.ttf') format('truetype');
}

// Playfair Display
@font-face {
  font-family: 'Playfair Display';
  src: local('Playfair Display'), url('./fonts/Playfair_Display/static/PlayfairDisplay-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Playfair Display Italic';
  src: local('Playfair Display Italic'),
    url('./fonts/Playfair_Display/static/PlayfairDisplay-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Playfair Display Medium';
  src: local('Playfair Display Medium'),
    url('./fonts/Playfair_Display/static/PlayfairDisplay-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Playfair Display Medium Italic';
  src: local('Playfair Display Medium Italic'),
    url('./fonts/Playfair_Display/static/PlayfairDisplay-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Playfair Display SemiBold';
  src: local('Playfair Display SemiBold'),
    url('./fonts/Playfair_Display/static/PlayfairDisplay-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Playfair Display SemiBold Italic';
  src: local('Playfair Display SemiBold Italic'),
    url('./fonts/Playfair_Display/static/PlayfairDisplay-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Playfair Display Bold';
  src: local('Playfair Display Bold'),
    url('./fonts/Playfair_Display/static/PlayfairDisplay-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Playfair Display Bold Italic';
  src: local('Playfair Display Bold Italic'),
    url('./fonts/Playfair_Display/static/PlayfairDisplay-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Playfair Display ExtraBold';
  src: local('Playfair Display ExtraBold'),
    url('./fonts/Playfair_Display/static/PlayfairDisplay-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Playfair Display ExtraBold Italic';
  src: local('Playfair Display ExtraBold Italic'),
    url('./fonts/Playfair_Display/static/PlayfairDisplay-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Playfair Display Black';
  src: local('Playfair Display Black'),
    url('./fonts/Playfair_Display/static/PlayfairDisplay-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Playfair Display Black Italic';
  src: local('Playfair Display Black Italic'),
    url('./fonts/Playfair_Display/static/PlayfairDisplay-BlackItalic.ttf') format('truetype');
}

// Poppins
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Italic';
  src: local('Poppins Italic'), url('./fonts/Poppins/Poppins-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Thin';
  src: local('Poppins Thin'), url('./fonts/Poppins/Poppins-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Thin Italic';
  src: local('Poppins Thin Italic'), url('./fonts/Poppins/Poppins-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins ExtraLight';
  src: local('Poppins ExtraLight'), url('./fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins ExtraLight Italic';
  src: local('Poppins Thin Italic'), url('./fonts/Poppins/Poppins-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Light';
  src: local('Poppins Light'), url('./fonts/Poppins/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Light Italic';
  src: local('Poppins Thin Italic'), url('./fonts/Poppins/Poppins-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Medium';
  src: local('Poppins Medium'), url('./fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Medium Italic';
  src: local('Poppins Medium Italic'), url('./fonts/Poppins/Poppins-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins SemiBold';
  src: local('Poppins SemiBold'), url('./fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins SemiBold Italic';
  src: local('Poppins SemiBold Italic'), url('./fonts/Poppins/Poppins-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Bold';
  src: local('Poppins Bold'), url('./fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Bold Italic';
  src: local('Poppins Bold Italic'), url('./fonts/Poppins/Poppins-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins ExtraBold';
  src: local('Poppins ExtraBold'), url('./fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins ExtraBold Italic';
  src: local('Poppins ExtraBold Italic'), url('./fonts/Poppins/Poppins-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Black';
  src: local('Poppins Black'), url('./fonts/Poppins/Poppins-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Black Italic';
  src: local('Poppins Black Italic'), url('./fonts/Poppins/Poppins-BlackItalic.ttf') format('truetype');
}

// Raleway
@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url('./fonts/Raleway/static/Raleway-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway Italic';
  src: local('Raleway Italic'), url('./fonts/Raleway/static/Raleway-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway Thin';
  src: local('Raleway Thin'), url('./fonts/Raleway/static/Raleway-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway Thin Italic';
  src: local('Raleway Thin Italic'), url('./fonts/Raleway/static/Raleway-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway ExtraLight';
  src: local('Raleway ExtraLight'), url('./fonts/Raleway/static/Raleway-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway ExtraLight Italic';
  src: local('Raleway ExtraLight Italic'), url('./fonts/Raleway/static/Raleway-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway Light';
  src: local('Raleway Light'), url('./fonts/Raleway/static/Raleway-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway Light Italic';
  src: local('Raleway Light Italic'), url('./fonts/Raleway/static/Raleway-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway Medium';
  src: local('Raleway Medium'), url('./fonts/Raleway/static/Raleway-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway Medium Italic';
  src: local('Raleway Medium Italic'), url('./fonts/Raleway/static/Raleway-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway SemiBold';
  src: local('Raleway SemiBold'), url('./fonts/Raleway/static/Raleway-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway SemiBold Italic';
  src: local('Raleway SemiBold Italic'), url('./fonts/Raleway/static/Raleway-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway Bold';
  src: local('Raleway Bold'), url('./fonts/Raleway/static/Raleway-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway Bold Italic';
  src: local('Raleway Bold Italic'), url('./fonts/Raleway/static/Raleway-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway ExtraBold';
  src: local('Raleway ExtraBold'), url('./fonts/Raleway/static/Raleway-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway ExtraBold Italic';
  src: local('Raleway ExtraBold Italic'), url('./fonts/Raleway/static/Raleway-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway Black';
  src: local('Raleway Black'), url('./fonts/Raleway/static/Raleway-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway Black Italic';
  src: local('Raleway Black Italic'), url('./fonts/Raleway/static/Raleway-BlackItalic.ttf') format('truetype');
}

//Roboto Slab
@font-face {
  font-family: 'Roboto Slab';
  src: local('Roboto Slab'), url('./fonts/Roboto_Slab/static/RobotoSlab-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Slab Thin';
  src: local('Roboto Slab Thin'), url('./fonts/Roboto_Slab/static/RobotoSlab-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Slab ExtraLight';
  src: local('Roboto Slab ExtraLight'), url('./fonts/Roboto_Slab/static/RobotoSlab-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Slab Light';
  src: local('Roboto Slab Light'), url('./fonts/Roboto_Slab/static/RobotoSlab-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Slab Medium';
  src: local('Roboto Slab Medium'), url('./fonts/Roboto_Slab/static/RobotoSlab-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Slab SemiBold';
  src: local('Roboto Slab SemiBold'), url('./fonts/Roboto_Slab/static/RobotoSlab-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Slab Bold';
  src: local('Roboto Slab Bold'), url('./fonts/Roboto_Slab/static/RobotoSlab-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Slab ExtraBold';
  src: local('Roboto Slab ExtraBold'), url('./fonts/Roboto_Slab/static/RobotoSlab-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Slab Black';
  src: local('Roboto Slab Black'), url('./fonts/Roboto_Slab/static/RobotoSlab-Black.ttf') format('truetype');
}

// Roboto
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('./fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Italic';
  src: local('Roboto Italic'), url('./fonts/Roboto/Roboto-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Thin';
  src: local('Roboto Thin'), url('./fonts/Roboto/Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Thin Italic';
  src: local('Roboto Thin Italic'), url('./fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Light';
  src: local('Roboto Light'), url('./fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Light Italic';
  src: local('Roboto Light Italic'), url('./fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Medium';
  src: local('Roboto Medium'), url('./fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Medium Italic';
  src: local('Roboto Medium Italic'), url('./fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Bold';
  src: local('Roboto Bold'), url('./fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Bold Italic';
  src: local('Roboto Bold Italic'), url('./fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Black';
  src: local('Roboto Black'), url('./fonts/Roboto/Roboto-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Black Italic';
  src: local('Roboto Black Italic'), url('./fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Josefin Sans';
  src: local('Josefin Sans'), url('./fonts/JosefinSans/JosefinSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Josefin Sans Thin';
  src: local('Josefin Sans Thin'), url('./fonts/JosefinSans/JosefinSans-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Josefin Sans Thin Italic';
  src: local('Josefin Sans Thin Italic'), url('./fonts/JosefinSans/JosefinSans-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Josefin Sans SemiBold';
  src: local('Josefin Sans SemiBold'), url('./fonts/JosefinSans/JosefinSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Josefin Sans SemiBold Italic';
  src: local('Josefin Sans SemiBold Italic'), url('./fonts/JosefinSans/JosefinSans-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Josefin Sans Medium';
  src: local('Josefin Sans Medium'), url('./fonts/JosefinSans/JosefinSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Josefin Sans Medium Italic';
  src: local('Josefin Sans Medium Italic'), url('./fonts/JosefinSans/JosefinSans-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Josefin Sans Light';
  src: local('Josefin Sans Light'), url('./fonts/JosefinSans/JosefinSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Josefin Sans Light Italic';
  src: local('Josefin Sans Light Italic'), url('./fonts/JosefinSans/JosefinSans-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Josefin Sans Italic';
  src: local('Josefin Sans Italic'), url('./fonts/JosefinSans/JosefinSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Josefin Sans ExtraLight';
  src: local('Josefin Sans ExtraLight'), url('./fonts/JosefinSans/JosefinSans-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Josefin Sans ExtraLight Italic';
  src: local('Josefin Sans ExtraLight Italic'), url('./fonts/JosefinSans/JosefinSans-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Josefin Sans Bold';
  src: local('Josefin Sans Bold'), url('./fonts/JosefinSans/JosefinSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Josefin Sans Bold Italic';
  src: local('Josefin Sans Bold Italic'), url('./fonts/JosefinSans/JosefinSans-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Turbinado Bold Pro';
  src: local('Turbinado Bold Pro'), url('./fonts/TurbinadoBoldPro/TurbinadoBoldPro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Silk Serif';
  src: local('Silk Serif'), url('./fonts/SilkSerif/SilkSerif-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Silk Serif Italic';
  src: local('Silk Serif Italic'), url('./fonts/SilkSerif/SilkSerif-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Silk Serif Bold';
  src: local('Silk Serif Bold'), url('./fonts/SilkSerif/SilkSerif-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Silk Serif Bold Italic';
  src: local('Silk Serif Bold Italic'), url('./fonts/SilkSerif/SilkSerif-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Silk Serif Black';
  src: local('Silk Serif Black'), url('./fonts/SilkSerif/SilkSerif-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Silk Serif Black Italic';
  src: local('Silk Serif Black Italic'), url('./fonts/SilkSerif/SilkSerif-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Silk Serif ExtraLight';
  src: local('Silk Serif ExtraLight'), url('./fonts/SilkSerif/SilkSerif-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Silk Serif ExtraLight Italic';
  src: local('Silk Serif ExtraLight Italic'), url('./fonts/SilkSerif/SilkSerif-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Silk Serif Light';
  src: local('Silk Serif Light'), url('./fonts/SilkSerif/SilkSerif-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Silk Serif Light Italic';
  src: local('Silk Serif Light Italic'), url('./fonts/SilkSerif/SilkSerif-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Silk Serif Medium';
  src: local('Silk Serif Medium'), url('./fonts/SilkSerif/SilkSerif-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Silk Serif Medium Italic';
  src: local('Silk Serif Medium Italic'), url('./fonts/SilkSerif/SilkSerif-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Silk Serif Medium';
  src: local('Silk Serif Medium'), url('./fonts/SilkSerif/SilkSerif-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Silk Serif Medium Italic';
  src: local('Silk Serif Medium Italic'), url('./fonts/SilkSerif/SilkSerif-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Silk Serif SemiBold';
  src: local('Silk Serif SemiBold'), url('./fonts/SilkSerif/SilkSerif-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Silk Serif SemiBold Italic';
  src: local('Silk Serif SemiBold Italic'), url('./fonts/SilkSerif/SilkSerif-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Bianco Serif';
  src: local('Bianco Serif'), url('./fonts/BiancoSerif/BiancoSerif-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Bianco Serif Bold';
  src: local('Bianco Serif Bold'), url('./fonts/BiancoSerif/BiancoSerif-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Bianco Serif Bold Italic';
  src: local('Bianco Serif Bold Italic'), url('./fonts/BiancoSerif/BiancoSerif-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Bianco Serif Italic';
  src: local('Bianco Serif Italic'), url('./fonts/BiancoSerif/BiancoSerif-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Bianco Serif Heavy';
  src: local('Bianco Serif Heavy'), url('./fonts/BiancoSerif/BiancoSerif-Heavy.ttf') format('truetype');
}

@font-face {
  font-family: 'Bianco Serif Heavy Italic';
  src: local('Bianco Serif Heavy Italic'), url('./fonts/BiancoSerif/BiancoSerif-HeavyItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Bianco Serif ExtraBold';
  src: local('Bianco Serif ExtraBold'), url('./fonts/BiancoSerif/BiancoSerif-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Bianco Serif ExtraBold Italic';
  src: local('Bianco Serif ExtraBold Italic'), url('./fonts/BiancoSerif/BiancoSerif-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Bianco Serif ExtraBold Italic';
  src: local('Bianco Serif ExtraBold Italic'), url('./fonts/BiancoSerif/BiancoSerif-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica';
  src: local('Helvetica'), url('./fonts/Helvetica/Helvetica-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Bodoni Book';
  src: local('Bodoni Book'), url('./fonts/Bodoni/Bodoni-Book.ttf') format('truetype');
}
