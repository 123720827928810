@import "variables.scss";
@import "typography.scss";

.root {
  @include font-defaults;
}

.input_icon {
  color: rgba(0,0,0,0.25);
}

.error_feedback {
  display: block;
  margin-bottom: 8px;
}

.login_button {
  width: 230px;
  margin-top: 16px;
  margin-bottom: 8px;
  
  span {
    font-weight: 500;
  }
}

.login_as_radio {
  text-align: center;
  padding-bottom: 10px;
}

.confirmation_code_label {
  padding-bottom: 20px;
  text-align: center;
}

.remember_me {
  text-transform: inherit;
}