// Generated with util/create-component.js
@import 'variables.scss';
@import 'typography.scss';
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600&display=swap');

.propertyInfoSection {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  justify-content: flex-start;
}

.hotelTitle {
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 20px;
}

.locationContainer {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 24px;
  color: #6c7683;
  letter-spacing: 0.2px;
  font-feature-settings: 'liga' off;
  border-bottom: 1px solid #D8DCE1;
  padding-bottom: 10px;
}

.locationIcon {
  margin-right: 5px;
}

.hotelAddress {
  font-size: 14px;
}

.hotelDescription {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  font-feature-settings: 'liga' off;
  padding-top: 30px;
  border-top: 1px solid #d8dce1;
  padding-right: 30px;
  margin-bottom: 40px;
  margin-bottom: 20px;
}
