@import 'variables.scss';
@import 'typography.scss';

.root {
  @include font-defaults;

  align-items: center;
  box-shadow: 0 2px 8px #f0f1f2;
  display: flex;
  justify-content: space-between;
  padding: 2px 10px;
}

.logo_container {
  display: flex;
  align-items: center;
}

.logo {
  margin-right: 60px;
}

.left_content {
  align-items: center;
  color: $tbf-white;
  display: flex;
  justify-content: center;
  margin-left: auto;
}

.nav_link {
  cursor: pointer;
  display: flex;
  font-weight: 450;
  font-size: 15px;
  justify-content: center;
  align-items: center;
  height: 50px;
  color: $tbf-darkGrey;
  margin: 0 8px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

.nav_link:hover {
  color: $tbf-blue;
  border-bottom: 2px solid $tbf-blue;
}

.nav_link_active {
  @extend .nav_link;
  color: $tbf-blue;
  border-bottom: 2px solid $tbf-blue;
}

.button {
  background-color: transparent;
  border: none;
  color: $tbf-darkGrey;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav_language {
  @extend .nav_link;
  width: 30px;
  margin-left: 0;
  margin-right: 10px;
}

.logo {
  cursor: pointer;
  height: 50px;
  width: 100px;
}

.search_property_wrapper {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  padding: 10px;
  background: $tbf-white;
}

.gift_card {
  @extend .nav_link;
  border: none;
}

.disabled_gift_card {
  @extend .gift_card;
  color: darkgray !important;
}

.gift_card_active,
.gift_card:focus {
  @extend .gift_card;
  border-bottom: 2px solid $tbf-blue;
  color: $tbf-blue;
}

.notification_container {
  display: flex;
  justify-content: center;
  align-items: center;
  .notification {
    font-size: 18px;
    cursor: pointer;
    color: $tbf-black;
    &:focus,
    &:hover {
      color: $tbf-blue;
    }
  }
}

.disabled_nav_link{
  @extend .nav_link;
  color: darkgray !important;
}

.disabled_nav_link:hover {
  color: darkgray;
  border-bottom: 2px solid darkgray;
}

.disabled_menu_item {
  color: darkgray !important;
}
