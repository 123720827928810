// Generated with util/create-component.js
@import 'variables.scss';
@import 'typography.scss';
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600&display=swap');

.root {
  @include font-defaults;
}

.search_input {
  margin-bottom: 20px;
}
