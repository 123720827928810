// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.root {
  @include font-defaults;
  padding-bottom: 40px;

  :global(.ant-select-item-group),
  :global(.ant-form-item-label > label) {
    text-transform: capitalize;
  }

  :global(.ant-select-selection-item-remove) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  :global(.ant-form-item) {
    margin-bottom: 3px;
  }

  :global(.ant-radio-button-wrapper) {
    text-align: center;
    text-transform: capitalize;
  }

  :global(.ant-radio-group) {
    width: 100%;
  }
}

.room_type_form {
  margin-top: 20px;
  margin-bottom: 20px;
}

.loading_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.occupancy_description {
  white-space: pre-wrap;
  font-size: 80%;
  padding: 5px 0 10px 0;
  color: #929292;
  font-family: Helvetica;
}

.occupancy_example_description {
  padding: 10px 0;
}

.occupancy_general_description {
  font-size: 14px;
  padding-bottom: 20px;
  font-family: Helvetica;
}
