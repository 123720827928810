// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;
  white-space: pre-wrap;

  :global(label) {
    text-align: left;
    display: inline-flex;
    width: 100%;
    text-transform: capitalize;
    font-family: inherit;
  }

  :global(.ant-form-item) {
    margin-bottom: 5px;
  }
}