// Generated with util/create-component.js
@import "variables.scss";
@import "typography.scss";

.root {
  @include font-defaults;
  overflow-y: auto;
}
.loading_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

