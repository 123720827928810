@import "variables.scss";
@import "typography.scss";

.root {
  @include font-defaults;
  padding: 24px;
}

.login_container {
  max-width: 400px;
  margin: 0px auto;
}

.logo_container {
  text-align: center;
  margin: 30px 0;
}
