// Generated with util/create-component.js
@import '../../../variables.scss';
@import '../../../typography.scss';

.root {
  @include font-defaults;

  :global(.ant-table-cell a) {
    color: $tbf-blue;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
  }

  :global(.ant-pagination-item a),
  :global(.ant-pagination-item-ellipsis) {
    line-height: 30px !important;
  }

  :global(.ant-pagination-item-link) {
    svg {
      vertical-align: unset;
    }
  }
}

.search_input_container {
  margin-bottom: 20px;
}
