@import 'variables.scss';
@import 'typography.scss';

.root {
  align-items: center;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 767px) {
  .root {
    padding: 0 25px 25px 25px;
  }
}