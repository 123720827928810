@import "variables.scss";
@import "typography.scss";

.button:global.btn-link {
  color: $tbf-darkerGrey;
  text-decoration: underline;
  border: none;
  padding: 0;
  line-height: 20px;
}

.button:global.btn-link:hover,
.button:global.btn-link:active,
.button:global.btn-link:focus {
  box-shadow: none;
  border: none;
}