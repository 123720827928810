// Generated with util/create-component.js
@import 'variables.scss';
@import 'typography.scss';

.root {
  background-color: #f1f5f7;
  height: 100%;

  .pageTitleWrapper {
    padding: 0 20px;
  }

  .bookByGiftLetterWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #f1f5f7;

    .bookByGiftLetter {
      padding: 40px;
      width: auto;
      height: fit-content;
      background-color: $tbf-white;
      margin-bottom: 20px;
      max-width: 850px;
    }
  }
}

@media screen and (max-width: 767px) {
  .root {
    .pageTitleWrapper {
      padding: 0;
    }

    .bookByGiftLetterWrapper {
      padding: 25px;

      .bookByGiftLetter {
        padding: 30px;
        margin: 0;
      }
    }
  }
}
