// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.root {
  @include font-defaults;
  font-size: 16px;

  :global(.ant-image-mask-info) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.optionImageContainer {
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
  }
}

.optionContainer {
  border-radius: 20px;
  width: 100%;
  background-color: $tbf-white;
  padding: 20px 0;
}

.expirationDate {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.descriptionContainer {
  height: 100%;
  padding-left: 20px;
}

@media screen and (max-width: 575px) {
  .descriptionContainer {
    padding-top: 20px;
    padding-left: 0;
  }
}

@media screen and (max-width: 1600px) {
  .optionImageContainer {
    height: 250px;
  }
}

.optionName {
  font-weight: 600;
  font-size: 20px;
  color: $tbf-black;
  padding-bottom: 10px;
}

.optionExpiration {
  padding-top: 20px;
}
