// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;
  padding: 20px 0;
  width: 80%;
  margin: 0 auto;
}

.createButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $tbf-blue;
  cursor: pointer;
  margin-bottom: 25px;
  float: right;
}
