@import 'variables.scss';

.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #f1f5f7;
  width: 100%;
}

.giftCardStoreWrapper {
  height: fit-content;
  width: 100%;
  position: relative;
}

.headerContainer {
  position: relative;
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  justify-content: flex-end;
  min-height: 400px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .headerNote {
    background-color: $tbf-white;
    color: #000000;
    position: sticky;
    float: right;
    margin: 40px;
    top: 100px;
    font-size: 46px;
    padding: 40px;
    line-height: 1;
    margin-bottom: -60px;
    width: 60%;
  }

  .headerNoteTitle {
    font-weight: 700;
    font-size: 72px;
    line-height: 88px;
    padding-bottom: 25px;
  }

  .headerNoteDescription {
    font-weight: 400;
    font-size: 22px;
    line-height: 40px;
  }
}

.searchInputContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 40px;
}

.giftCardsContainer {
  padding: 40px;
  width: 100%;
}

@media screen and (max-width: 1567px) {
  .headerContainer {
    min-height: 300px;

    .headerNote {
      top: 120px;
    }
  }
}

@media screen and (max-width: 767px) {
  .headerContainer {
    min-height: 230px;

    .headerNote {
      top: 100px;
      right: 25px;
      left: 25px;
      width: calc(100% - 50px);
    }

    .headerNoteTitle {
      font-size: 40px;
      line-height: 42px;
      font-weight: 700;
    }
    .headerNoteDescription {
      font-size: 18px;
      line-height: 28px;
    }
  }
}
