// Generated with util/create-component.js
@import '../../../variables.scss';
@import '../../../typography.scss';

.label {
  text-align: right;
  font-weight: 500;
}

.notes {
  white-space: pre-wrap;
}
