// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;
}

.actions_link {
  display: inline-flex;
  text-align: right;
  align-items: center;
  color: $tbf-blue;
  cursor: pointer;
  justify-content: flex-end;
  width: auto;
  float: right;
}
