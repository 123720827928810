// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.root {
  @include font-defaults;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 30px auto;
}

@media screen and (max-width: 767px) {
  .root {
    padding-bottom: 100px;
  }
}

.createButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $tbf-blue;
  cursor: pointer;
  float: right;
  margin-bottom: 20px;
}
