@import "variables.scss";

.wrapper {
  flex: 1;
  background: #f9fbfd;
  height: 100vh;
  padding-bottom: 150px;
}

.list {
  padding: 20px;
  overflow: auto;
  max-height: 100%;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
}

.listGrid {
  display: grid;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background: #ffffff;
  box-shadow: 0 5px 20px 0 rgba(231, 238, 245, 0.77);
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  align-self: center;
  flex: 1;
  min-height: 580px;

  .show_prices {
    z-index: 1000;
    position: absolute;
  }
}

.item:hover .overlay,
.itemHighlighted .overlay {
  display: block;
}

.providerLogo {
  height: 50px;
  margin-bottom: 10px;
}


.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  background-color: #0d70cd6b;
  transition: all 200ms ease;
  pointer-events: none;
  z-index: 1;
}

.previewBtnWrapper {
  height: 220px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageWrapper {
  border-bottom: 1px solid #f1f8fe;
  height: 220px;
  position: relative;
  box-sizing: border-box;
}

.previewButton {
  color: white;
  font-weight: bold;
  border: solid 1px white;
  border-radius: 4px;
  padding: 8px;
}

.previewButton:global.btn:focus,
.previewButton:global.btn:active,
.previewButton:global.btn-primary:not(:disabled):not(.disabled):active {
  box-shadow: none;
  background-color: transparent;
  border-color: #fff;
}

.image {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.emptyImage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.info {
  padding: 25px 20px 20px 20px;
}

.title {
  font-family: Helvetica;
  font-size: 20px;
  color: #232331;
}

.propertyDetails {
  font-family: Helvetica;
  font-size: 13px;
  color: #232331;
}

.vehicleDetailsRow {
  display: flex;
  width: 70%;
  justify-content: space-between;

  .vehicleDetails {
    @extend .propertyDetails;
    font-size: 18px;
    padding-left: 5px;
  }

  .vehicleDetailsIcon {
    height: 30px;
  }

  .smallVehicleDetailsIcon {
    height: 20px;
  }
}

.description {
  font-family: Helvetica;
  font-size: 13px;
  color: #566879;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 36px;
  height: 36px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #f1f8fe;
  padding: 20px;
  box-sizing: border-box;
}

.seeMoreLink {
  font-family: Helvetica;
  font-size: 14px;
  color: #0d70cd;
  text-align: right;
}

.seeMoreLink:hover {
  text-decoration: none;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 100px);
}

.emptyWrapper {
  display: flex;
  width: 100%;
  height: calc(100vh - 100px);
  align-items: center;
  justify-content: center;
}

.empty {
  font-size: 26px;
  color: #232331;
}

.skeleton {
  border-radius: 2px;
  background: #{linear-gradient(180deg, lightgray, #f9fbfd, lightgray, #f9fbfd)};
  animation: gradient 3s infinite;
  background-size: 100% 300%;
  opacity: 0.5;

  p span {
    width: 100%;
    min-height: 12px;
    background-color: #f9fbfd;
    border-radius: 2px;
    display: inline-block;
  }

  .title span {
    height: 24px;
  }

  .footer {
    .bestOffer span,
    .seeMoreLink span {
      width: 120px;
      height: 24px;
    }
  }

}

@keyframes gradient {
  0% { background-position: 0% 100%; }
  100% { background-position: 0% 0%; }
}
