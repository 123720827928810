.cell {
  position: relative;
  display: block;
  width: 100%;
  min-height: 40px;
  padding: 8px 8px 8px 0px;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid rgba(36, 46, 66, 0.12);
}

.cellNoLine {
  border-bottom-style: none;
}

.cellNoPadding {
  padding-right: 0px;
}