// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.root {
  @include font-defaults;
}

.datePicker {
  width: 100%;
  margin-bottom: 20px;
}

.datePickerLabel {
  font-size: 12px;
  line-height: 18px;
}

@media screen and (min-width: 767px) {
  .datePickerContainer {
    padding-right: 20px;
  }
}
