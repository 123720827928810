// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;
}

.item img {
  max-width: 100%;
  height: 400px;
  object-fit: cover;
  object-position: center;
}

@media screen and (max-width: 1567px) {
  .item img {
    height: 300px;
  }
}

@media screen and (max-width: 767px) {
  .item img {
    height: 200px;
  }
}

.imagesWrapper {
  margin-top: 20px;
}

.imageContainer {
  padding-right: 10px;
}

.imageWrapper {
  cursor: pointer;
  padding: 1px;
}

.selectedImageWrapper {
  border: 2px solid $tbf-black;
}

.galleryImage {
    object-fit: contain;
  }

@media screen and (max-width: 1266px) {
  .galleryImage {
    height: 40px;
  }
}

@media screen and (min-width: 1267px) {
  .galleryImage {
    height: 60px;
  }
}

@media screen and (max-width: 500px) {
  .galleryImage {
    height: 60px;
  }
}

@media screen and (min-width: 1867px) {
  .galleryImage {
    height: 100px;
  }
}
