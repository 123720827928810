// Generated with util/create-component.js
@import '../../../variables.scss';
@import '../../../typography.scss';

.root {
  @include font-defaults;

  :global(.ant-table-cell a) {
    color: $tbf-blue;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    transition: color 0.3s;
  }
}

.statusLabel {
  text-transform: capitalize;
  text-decoration: underline;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 150px;
}

.statusProposedIcon {
  color: $tbf-green;
}

.statusRequestedIcon {
  color: $tbf-blue;
}

.actionsLinksTitle {
  text-align: right;
}

.actionsLinksWrapper {
  display: flex;
  justify-content: flex-end;
}
