@import 'variables.scss';

.giftCard {
  padding-right: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.giftCardContainer {
  background-color: $tbf-white;
  padding: 25px;
  height: auto;
  margin-bottom: 40px;
  max-width: 370px;
}

.popoverContent {
  width: auto;
  max-width: 300px;
}

.popoverWrap:hover {
  cursor: pointer;  
}

@media screen and (max-width: 767px) {
  .giftCardContainer {
    margin-bottom: 25px;
  }

  .giftCard {
    padding-right: 0;
  }
}

.imageContainer {
  height: 190px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

@media screen and (max-width: 1200px) {
  .imageContainer {
    height: 180px;
  }
}

.giftCardDetails {
  font-size: 20px;
  margin: 20px 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 44px;
  height: auto;
  font-weight: 700;
}

.giftCardPriceDetails {
  height: 70px;
}

.giftCardName {
  @extend .giftCardDetails;
  cursor: pointer;
  line-height: 22px;

  &:hover {
    text-decoration: underline;
  }
}

.giftCardPrice {
  @extend .giftCardDetails;
  margin: 30px 0 0 0;
  font-size: 24px;
  font-weight: 700;
}

.giftCardDescription {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 88px;
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  user-select: none;
}

.buttonContainer {
  height: 100%;
  justify-content: center;
  align-items: flex-end;

  .chooseButton {
    width: 100%;
  }
}
