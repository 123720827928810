// Generated with util/create-component.js
@import 'variables.scss';
@import 'typography.scss';

.root {
  @include font-defaults;

  :global(.ant-form-item) {
    margin-bottom: 3px;
  }

  :global(.ant-form-horizontal) {
    min-height: calc(100vh - 140px);
    display: flex;
    flex-direction: column;
  }

  :global(.ant-radio-button-wrapper) {
    width: 50%;
    text-align: center;
    text-transform: capitalize;
  }

  :global(.ant-radio-group) {
    width: 100%;
  }

  :global(.ant-select-item-group),
  :global(.ant-form-item-label > label) {
    text-transform: capitalize;
  }

  :global(.ant-upload-list-picture-card) {
    width: 100%;
  }

  :global(.ant-upload-list-picture-card-container) {
    width: 32%;
    text-align: center;
    justify-content: center;
    display: flex;
    position: relative;
    min-width: 150px;
    max-width: 200px;
    height: 150px;
  }

  :global(.ant-upload-list-picture-card) {
    ::before {
      width: 100%;
    }
  }

  :global(.ant-upload.ant-upload-select.ant-upload-select-picture-card) {
    width: 32%;
    text-align: center;
    justify-content: center;
    display: flex;
    max-width: 250px;
    min-width: 200px;
    min-height: 150px;
  }

  :global(.ant-upload-list.ant-upload-list-picture-card) {
    display: flex;
    flex-wrap: wrap;
  }

  :global(.ant-input-number-group-wrapper) {
    width: 100%;
  }

  :global(.ant-input-number.ant-input-number-in-form-item) {
    width: 100%;
  }
}

.submit_section {
  margin-top: auto;
  margin-bottom: -30px;
}

.button_section {
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  border-top: 1px solid #e8e8e8;
}

.submit_button {
  width: 100%;
}

.total_net_price {
  text-align: right;
}

.recipientsList {
  margin-bottom: 8px;
}

.copyButton {
  padding-top: 7px;
}
