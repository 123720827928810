@import 'variables.scss';
@import 'typography.scss';
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600&display=swap');

.root {
  padding: 30px;

  :global(.ant-form-item-label > label) {
    text-transform: capitalize;
  }
}

.form {
  width: 25vw;
}

.title {
  font-family: 'Merriweather';
  display: flex;
  justify-content: center;
  font-size: 35px;
  margin: 10px auto 50px auto;
}

.wrapper {
  margin-bottom: 10px;
}

.form_item {
  padding: 0 10px;
}

.submit_button {
  width: 250px;
  background-color: rgb(74, 74, 74);
  border-color: rgb(74, 74, 74);
  color: #ffffff;
  font-size: 16px;
  width: -webkit-fill-available;
  margin: 0 10px;
}

.submit_button:hover {
  background-color: rgb(108, 108, 108);
  border-color: rgb(108, 108, 108);
  color: #ffffff;
}
