// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.root {
  @include font-defaults;
}

.defaultLanguage {
  text-transform: uppercase;
  font-weight: bold;
  color: $tbf-black;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
}
