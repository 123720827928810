@import 'variables.scss';
@import 'typography.scss';

.root {
  :global(.ant-table-cell) {
    text-align: center !important;
  }
}

.activeStatus {
  font-weight: 500;
}

.cancelledStatus {
  color: $tbf-red;
  font-weight: 500;
}

.cancelButtonEnabled {
  background-color: $tbf-red;
  color: $tbf-white;
}

.cancelButtonDisabled {
  color: $tbf-white;
}

.filterDropdownWrapper {
  padding: 20px 10px;
}

.filterButton {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 15px 10px;
}

.filterDropdownInput {
  display: block;
  margin-bottom: 8px;
}
