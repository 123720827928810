// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;
}

.flexContainer {
  display: flex;
  flex-wrap: nowrap;
}

.flexGrow {
  flex-grow: 1;
}

.flexStatic {
  flex: 0 0 auto;
  margin-left: 10px;
}

.rangePicker {
  width: 220px;
}

.selectStatus {
  width: 110px;
}

.selectDelivery {
  width: 155px;
}

.selectKind {
  width: 110px;
}
 
@media screen and (max-width: 1190px) {
  .flexContainer {
    flex-direction: column;
    flex-wrap: wrap;
    display: block;
  }

  .flexStatic, .flexGrow {
    margin-left: 0;
    width: 100%;
  }

  .spaceStyle {
    display: block;
  }

}
