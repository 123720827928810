// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.root {
  @include font-defaults;
  padding: 10px;
}

.row {
  border-bottom: 1px solid $tbf-lightGrey;
  color: rgba(0, 0, 0, 0.85);
  padding: 10px 0;
}
