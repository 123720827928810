// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;
}

.full_width {
  width: 100%;
}

.menu {
  margin-bottom: 30px;
}
