.unitDetailsContainer {
  width: 100%;
  padding-bottom: 8px;
}

.unitDetailsContent {
  display: flex;
  flex-wrap: wrap;
}

.unitDetailsHeader {
  margin: 8px 0;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.15px;
}