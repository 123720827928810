@import 'variables.scss';

.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #f1f5f7;
  width: 100%;
  padding: 10px;
}
