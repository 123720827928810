// Generated with util/create-component.js
@import '../../../../variables.scss';
@import '../../../../typography.scss';

.root {
  @include font-defaults;
}

.roomRateTitle {
  color: $tbf-blue;
  font-size: 14px;
  font-weight: 500;
}

.ratePlanTitle {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.occupancyIcon {
  padding-right: 5px;
}

.button {
  color: $tbf-white;
  background-color: $tbf-green;
  border: $tbf-green;
  width: 49%;
  padding: 10px;
  height: 40px;
}

.acceptContractButton {
  @extend .button;
  background-color: $tbf-green;
  border: $tbf-green;
  &:focus,
  &:hover {
    color: $tbf-white;
    background-color: $tbf-green;
    border: $tbf-green;
  }
}

.declineContractButton {
  @extend .button;
  background-color: $tbf-red;
  border: $tbf-red;
  &:focus,
  &:hover {
    color: $tbf-white;
    background-color: $tbf-red;
    border: $tbf-red;
  }
}

.cancelProposalButton {
  @extend .declineContractButton;
  width: 100%;
}

.statusLabel {
  font-size: 18px;
  color: black;
  margin: 15px 0;
  text-transform: capitalize;
  font-weight: 500;
}

.detailsLabel {
  @extend .statusLabel;
  font-size: 14px;
  > div {
    margin: 10px 0;
  }
}

.stepsWrapper {
  margin: 30px 0;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
