@import "variables.scss";
@import "typography.scss";

.roomContainer {
  margin-bottom: 20px;
}

.timesWrapper {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: -2px;
  margin-top: -2px;
}

.timesFromTo {
  width: 100%;
}

.timeTo,
.timeFrom {
  height: 48px;
  padding-right: 8px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.timeTo {
  padding-left: 8px;
}

.timeFrom {
  border-right: 1px solid #e6e6e6;
}

.icon {
  margin-top: 2px;
  padding-right: 8px;
}

.timePicker {
  margin-bottom: 0px;
}

.cell {
  margin-bottom: 12px;
}

.icon {
  font-size: 20px;
}
