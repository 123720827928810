// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;
  padding-top: 40px;

  :global(.ant-form-item-label > label) {
    text-transform: capitalize;
  }
}

.footer {
  display: flex;
    justify-content: flex-end;
}

.button {
  color: $tbf-black;
  background-color: $tbf-white;
  border-color: $tbf-logoBlue;
  border: 2px solid $tbf-logoBlue;
  float: right;
  &:focus,
  &:hover {
    background-color: $tbf-logoBlue;
  }
}

.cancelButton {
  @extend .button;
  border-color: $tbf-lightGrey;
}

.saveButton {
  @extend .button;
  margin-left: 10px;
}
