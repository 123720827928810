@import 'variables.scss';

.root {
  width: 100%;
  :global(.ant-form-item-label > label) {
    text-transform: capitalize;
  }

  :global(.ant-form-item-label) {
    text-align: left;
  }
}

.checkoutContainer {
  display: flex;
}

.generateContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $tbf-lightGrey;
  padding: 25px;
}

.infoHeader {
  padding-bottom: 20px;
  font-size: 24px;
  line-height: 32px;
}

.inputContainer {
  display: flex;
  padding: 40px 20px;
  border: 1px solid #e7e7e7;
}

.submitButton {
  font-family: Roboto, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  width: 100%;
  font-size: 20px;
  background-color: rgb(74, 74, 74);
  border-color: rgb(74, 74, 74);
}

.submitButton:hover {
  background-color: rgb(108, 108, 108);
  border-color: rgb(108, 108, 108);
}

.summaryContainer {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #e7e7e7;
}

.bookingSummaryContainer {
  padding-left: 50px;
}

@media screen and (max-width: 767px) {
  .bookingSummaryContainer {
    padding-left: 0;
  }

  .checkoutContainer {
    display: flex;
    flex-direction: column-reverse;
  }
}

.bookingSummaryTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  padding-bottom: 25px;
}

.bookingPhoto {
  height: auto;
  max-height: 245px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.summaryInfoContainer {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
}

.propertyTitle {
  padding-bottom: 15px;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
}

.propertyAddress {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  font-feature-settings: 'liga' off;
  color: #6c7683;
  padding-bottom: 10px;
  margin-bottom: 30px;
  border-bottom: 1px solid #d8dce1;
}

.dateContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  margin-bottom: 10px;
}

.dateTitle {
  display: flex;
}

.checkinDetails {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  padding: 25px 0;
  :first-child {
    margin-right: 30px;
  }
}

.checkInTitle,
.checkOutTitle {
  width: 100%;
  font-size: 14px;
  line-height: 22px;
}

.roomTypeTitle {
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 15px;
  margin-bottom: 10px;
}
