@import 'variables.scss';
@import 'typography.scss';
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600&display=swap');

.cartItems {
  background-color: #f5f5f5;
}

.total {
  border-top: 1px solid #a4afbe;
  padding-top: 16px;
  margin-top: 20px;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.2px;

  .totalPrice {
    font-size: 24px;
  }
}

.discountPrice {
  font-size: 14px !important;
}

.cartItem {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.2px;
  padding-top: 10px;

  .cartItemPrice {
    font-weight: 700;
  }
}

@media screen and (max-width: 767px) {
  .cartItem {
    font-size: 14px;
  }

  .total {
    font-size: 16px;

    .totalPrice {
      font-size: 18px;
    }
  }
}
