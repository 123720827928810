$breakpoint: 640px;

.root {
  overflow: hidden;
}

.wrapper {
  display: flex;
  flex-direction: row-reverse;
  min-width: $breakpoint;
}

.left {
  width: 100%;
  max-width: 1400px;
  height: calc(100vh - 100px);
}

.right {
  width: 100%;
  position: relative;
  height: calc(100vh - 100px);
}

@media (max-width: $breakpoint) {

  .wrapper {
    min-width: auto;
    display: block;
    .left {
      height: 240px;
    }
  }
}
