// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.root {
  @include font-defaults;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.nationality {
  width: 100%;
}

.add_guest_button {
  color: $tbf-blue;
  cursor: pointer;
}

.guest_container {
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
}

.update_guests_button {
  margin-right: 15px;
}
