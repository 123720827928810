// Generated with util/create-component.js
@import "variables.scss";
@import "typography.scss";

.root {
  @include font-defaults;
  background-color: #f5f5f5;
  padding: 20px;
  margin-bottom: 10px;
}
.title {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
  color: $tbf-black;
}
.description {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 20px;
  font-size: 12px;
}
.tabIcon {
  position: relative;
  bottom: 2px;
}