// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.root {
  @include font-defaults;
  overflow-y: auto;
  background-color: #ffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;

  :global(.ant-input[type='color']) {
    height: 50px;
  }

  :global(.ant-select-item-group),
  :global(.ant-form-item-label > label) {
    text-transform: capitalize;
  }

  :global(.ant-upload-list-picture-card) {
    width: 100%;
  }

  :global(.ant-upload-list-picture-card-container) {
    width: 32%;
    text-align: center;
    justify-content: center;
    display: flex;
    position: relative;
    min-width: 150px;
    max-width: 200px;
    height: 150px;
  }

  :global(.ant-upload-list-picture-card) {
    ::before {
      width: 100%;
    }
  }

  :global(.ant-upload.ant-upload-select.ant-upload-select-picture-card) {
    width: 32%;
    text-align: center;
    justify-content: center;
    display: flex;
    max-width: 250px;
    min-width: 200px;
    min-height: 150px;
  }

  :global(.ant-upload-list.ant-upload-list-picture-card) {
    display: flex;
    flex-wrap: wrap;
  }

  .giftCardPhotoContainer {
    width: 100%;
    position: relative;
  }
  
  .deletePhotoButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: $tbf-white;
    color: $tbf-darkerGrey;
    font-size: 15px;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
  
  .giftCardPhoto {
    width: 100%;
    height: 150px;
    object-fit: contain;
    object-position: top;
  }
  
  .addPhoto {
    font-size: 20px;
  }
  
  .addNewPhoto {
    color: $tbf-blue;
  }
  
  .removeButton {
    background-color: $tbf-red;
    border-color: $tbf-red;
    float: right;
  }

  .tagColorContainer > div {
    display: flex;
    align-items: center;
  }
  
  .tagColor {
    height: 50px;
    width: 50px;
    padding: 0px;
    border: none;
    cursor: pointer;
  }
  .controlsRow {
    margin-bottom: 24px;
  }
  .priorityContainer {
    margin-bottom: 24px;
  }
  .draggableListItem {
    touch-action: none;
  }
  .dndTag {
    margin-top: 4px;
  }
  .storePriorityTooltip {
    svg { 
      margin-left: 5px;
      cursor: help;
    }
  }
}

@media screen and (min-width: 991px) {
  .root {
    height: 90vh;
  }
}


