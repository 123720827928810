// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.root {
  @include font-defaults;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 60px;
  margin-top: 60px;
  padding: 0 20px;

  :global(.ant-form-item) {
    margin-bottom: 5px;
  }

  :global(.ant-select-item-group),
  :global(.ant-form-item-label > label) {
    text-transform: capitalize;
  }
}

.footer {
  width: 100%;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
}

.loading_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form_wrapper {
  width: 500px;
}
