@import 'variables.scss';

$breakpoint: 640px;

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrapper {
  display: flex;
  justify-content: center;

  .range_picker {
    margin-bottom: 0;
    height: 54px;
    padding-left: 1.5em;
    input,
    input::placeholder {
      color: $tbf-darkGrey;
    }
  }

  :global(.ant-picker) {
    border: 1px solid $tbf-darkerGrey;
    border-left: none;
  }

  :global(.ant-select-selector) {
    border: 1px solid $tbf-darkerGrey !important;
  }
}

.occupancyDropDown {
  margin-bottom: 0;
  border-top: 1px solid $tbf-darkerGrey;
  border-bottom: 1px solid $tbf-darkerGrey;

  :global {
    .dropdown {
      height: 100%;
    }

    .dropdown-toggle {
      height: 100%;
      border: none;
      border-radius: 0;
      padding-right: 16px;
      border-top: 1px solid $tbf-darkerGrey;
    }

    .btn-primary:not(:disabled):not(.disabled):focus {
      border: none;
    }

    .btn-primary.dropdown-toggle {
      width: 195px;
    }

    .show > .btn-primary.dropdown-toggle {
      border: none;
    }

    .dropdown-menu.show {
      width: 305px;
      max-height: 305px;
      overflow: auto;
    }
  }
}

.driverDetails {
  display: flex;
  margin: 20px;
  .label {
    margin-right: 10px;
  }

  .driverResidency {
    padding-left: 20px;
  }
}

.searchBtn {
  background: $tbf-lightBlue;
  border-radius: 0 28px 28px 0;
  font-family: Helvetica;
  font-size: 18px;
  color: #ffffff;
  padding: 27px 28px;
  width: auto;
  transition: all 200ms ease;
  &:global.btn-primary {
    &:hover,
    &:focus,
    &:active {
      transition: all 200ms ease;
      background: #91c3fa;
    }
    &:not(:disabled):not(.disabled):active {
      background: #91c3fa;
    }
  }
}

.helperMessage {
  color:  $tbf-red;
  padding: 10px 0;
}

@media (max-width: $breakpoint) {
  .wrapper {
    display: block;

    .range_picker {
      width: 100%;
      border-radius: 0px;
      border: 1px solid $tbf-darkerGrey;
    }
  }

  .occupancyDropDown :global .btn-primary.dropdown-toggle {
    width: 100%;
    height: 52px;
    margin: 12px 0px;
    border: 1px solid $tbf-darkerGrey;
  }

  .driverDetails {
    margin: 7px 0 30px 0;
    > div {
      width: 50%;
      display: flex;
      flex-direction: column;
    }
  }

  .searchBtn {
    border-radius: 0px;
    width: 100%;
  }
}
