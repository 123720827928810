.logoContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 0;
	width: 100%;

  .logo {
		width: 150px;
  }
}