/// Generated with util/create-component.js
@import 'variables.scss';
@import 'typography.scss';

.root {
  @include font-defaults;
}

.filledSection {
  border: 1px solid #d9d9d9;
  padding: 24px;
  border-radius: 4px;
  margin: 10px;
}
