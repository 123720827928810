@import 'variables.scss';
@import 'typography.scss';

.header {
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1140px;
  margin: 0 auto;
}

.titleSection {
  display: flex;
  min-height: 80px;
  align-items: center;
  max-width: 100%;
  position: relative;
}

.selectSection {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
}

@media screen and (max-width: 575px) {
  .header {
    flex-direction: column-reverse;
  }

  .selectSection,
  .titleSection {
    justify-content: center;
  }

  .selectSection {
    height: 40px;
  }
}

.back_button {
  background-color: $tbf-blue;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  margin-right: 20px;
}

@media screen and (min-width: 971px) {
  .back_button {
    margin-left: -60px;
  }
}
