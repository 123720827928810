@import 'variables.scss';
@import 'typography.scss';

.root {
  width: 100%;
  padding: 0;

  :global(input) {
    border: none;
  }
}

.infoWrapper {
  background-color: #f5f5f5;
  padding: 20px;
  margin-bottom: 25px;
}

.readTerms {
  padding: 10px 0;
  text-decoration: underline;
}

.agreeTerms {
  cursor: pointer;
  padding-left: 10px;
}

.submitWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

// Hide Arrows From Input Number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.header {
  display: flex;
  justify-content: flex-start;
  margin: 10px auto 30px auto;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}

.giftCardPhoto {
  height: 40px;
  width: 40px;
  object-fit: cover;
  object-position: center;
}

.giftCardTitle {
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
}

.giftCardTitleContent {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.greetingMessageCheckbox {
  padding-bottom: 25px;
}

.discountPercentage {
  font-size: 12px !important;
  line-height: 16px !important;
}

@media screen and (max-width: 767px) {
  .root {
    padding: 25px;
  }

  .continueButton {
    width: 100%;
  }

  .header {
    font-size: 20px;
    line-height: 24px;
  }

  .infoWrapper {
    padding: 25px 25px 0 25px;
  }

  .paymentInfoWrapper {
    padding: 25px;
  }

  .giftCardTitle {
    font-size: 16px;
    line-height: 24px;
  }

  .giftCardPhoto {
    height: 50px;
    width: 50px;
  }
}
