@import "variables.scss";
@import "typography.scss";

$header_height: 50px;

.root {
  @include font-defaults;

  section {
    & > div:not([class]) {
      overflow-y: auto;
      height: 100vh;
      padding-bottom: $header_height;
      & > div:last-child[class^=footer] {
        margin-bottom: $header_height;
      }
    }
  }
  
  header {
      position: fixed;
      top: 0px;
      width: 100vw;
      z-index: 10;
    & > nav {
      box-shadow: none !important;
    }
  }
}

body {
  overflow: hidden;
}


