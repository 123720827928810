.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.footer > * {
  flex: 1 3 33%;
  padding: 0 10px 24px;
}

.footerWrapper {
  display: block;
  max-width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  align-items: stretch;
  margin: 0 auto;
  padding: 56px 16px;
}

@media screen and (max-width: 991px) {
  .footer {
    display: block;
  }

  .footerWrapper {
    margin-bottom: 80px;
  }
}