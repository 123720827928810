@import 'variables.scss';

$breakpoint: 640px;

.container {
  min-height: 100vh;
  background-size: cover;
  background-position: 50%;
  background-image: url('~static/main-bg.svg');
  display: flex;
  justify-content: center;
  align-items: center;
}

.search_container {
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.emptyWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.logo_container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.subTitle {
  font-family: Helvetica;
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 48px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
}

.headerContainer {
  position: absolute;
  width: 100%;
}

.headerContainer :global .btn-link {
  color: white;
}

.sub_title_text {
  width: 400px;
  color: black;
  font-size: 32px;
  font-weight: 200;
}

@media (max-width: $breakpoint) {
  .title {
    font-size: 20px;
  }

  .subTitle {
    display: none;
  }

  :global .ant-picker-dropdown {
    padding: 0px;
  }

  :global .ant-picker-panels {
    width: 100vw;
    flex-direction: column;
    height: 100vh;
    align-items: center;
  }
}
