// Generated with util/create-component.js
@import '../../../../variables.scss';
@import '../../../../typography.scss';

.root {
  @include font-defaults;
}

.searchInputContainer {
  margin-bottom: 20px;
}
