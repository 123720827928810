@import 'variables.scss';
@import 'typography.scss';

.root {
  @include font-defaults;
  
  .tab_root {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 30px auto;
    
    @media (min-width: 1441px) {
      width: 80%;
    }
  }
  .create_button {
    display: flex;
    justify-content: flex-end;
  }
}
