// Generated with util/create-component.js
@import '../../../variables.scss';
@import '../../../typography.scss';

.root {
  @include font-defaults;
}

.actionsLinksTitle {
  text-align: right;
}

.actionsLinksWrapper {
  display: flex;
  justify-content: flex-end;
}
