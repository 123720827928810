@import 'variables.scss';
@import 'typography.scss';

$titleFont: 'TT Norms Bold', sans-serif;
$textFont: 'TT Norms', sans-serif;
$tagColor: $tbf-black;

.root {
  color: $tbf-black;
  font-family: var(--textFont, $textFont);
  letter-spacing: 0.2px;
  :global(.discountPrice),
  .discountPrice {
    font-family: var(--titleFont, $titleFont);
    font-weight: 400;
    text-decoration-line: line-through;
    font-size: 16px;
    line-height: 21px;
    color: #6c7683;
  }

  :global(.discountPercentage),
  .discountPercentage {
    background-color: $tbf-black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    gap: 10px;
    color: $tbf-white;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    width: fit-content;
    float: right;
  }

  :global(.highlighted),
  .highlighted {
    font-family: var(--titleFont, $titleFont);
    font-weight: 700;
  }

  :global(.ant-badge) {
    font-family: var(--textFont, $textFont);
  }

  :global(.ant-checkbox-input) {
    border: 1px solid $tbf-black;
  }

  :global(.ant-form-item-label) {
    padding: 0;
    display: flex;
  }

  :global(.ant-form-item-explain-error) {
    font-family: sans-serif;
    font-size: 12px;
  }

  :global(.ant-form-item-label > label) {
    text-transform: inherit;
    font-size: 12px;
  }

  :global(.ant-spin) {
    color: $tbf-black;
  }

  // white button
  :global(.ant-btn),
  :global(.whiteButton),
  .whiteButton {
    font-weight: 600;
    font-size: 14px;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $tbf-white;
    border: 2px solid var(--tagColor, $tagColor);
    color: var(--tagColor, $tagColor);
    &:hover,
    &:focus {
      background-color: var(--tagColor, $tagColor);
      border: 2px solid var(--tagColor, $tagColor);
      color: $tbf-white;
      box-shadow: none;
    }
    &:disabled {
      background-color: inherit;
      color: lightgrey;
      border-color: lightgrey;
    }
  }

  :global(.ant-picker) {
    border-color: #d8dce1;
  }

  :global(.ant-picker-focused) {
    border-color: #d8dce1;
  }

  // black button
  :global(.blackButton),
  .blackButton {
    background-color: var(--tagColor, $tagColor);
    border: 2px solid var(--tagColor, $tagColor);
    color: $tbf-white;
    &:hover,
    &:focus {
      background-color: $tbf-white;
      border: 2px solid var(--tagColor, $tagColor);
      color: var(--tagColor, $tagColor);
      box-shadow: none;
    }
    &:disabled {
      background-color: inherit;
      color: lightgrey;
      border-color: lightgrey;
    }
  }

  :global(.ant-input-affix-wrapper),
  :global(.ant-input),
  :global(.ant-select-selector) {
    border-color: $tbf-black !important;
    color: $tbf-black !important;
    box-shadow: none;
    font-family: sans-serif;
    &:hover,
    &:focus {
      border-color: $tbf-black !important;
      color: $tbf-black !important;
      box-shadow: none;
    }
  }

  :global(input),
  :global(textarea) {
    color: $tbf-black !important;
    box-shadow: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  :global(.ant-checkbox-wrapper) {
    text-transform: inherit;
    font-size: 16px;
    box-shadow: none;
    font-family: var(--textFont, $textFont);
  }

  :global(.ant-checkbox-inner) {
    border-color: $tbf-black;
    box-shadow: none;
  }

  :global(.ant-checkbox-checked .ant-checkbox-inner) {
    background-color: $tbf-black;
    box-shadow: none;
  }

  // rounded button and wrapper
  &.roundedTheme {
    :global(.discountPercentage),
    .discountPercentage {
      border-radius: 32px;
    }

    :global(.ant-picker) {
      border-radius: 16px;
    }

    :global(.ant-btn),
    :global(.roundedButton) {
      border-radius: 30px;
    }

    :global(.roundedWrapper),
    .roundedWrapper {
      border-radius: 16px;
    }

    :global(.roundedImage) {
      border-radius: 4px;
    }

    :global(.ant-input-affix-wrapper),
    :global(.ant-input) {
      border-radius: 24px;
      &:hover,
      &:focus {
        border-radius: 24px;
      }
    }

    :global(input),
    :global(textarea),
    :global(.ant-select-selector) {
      border-radius: 16px !important;
    }

    :global(.ant-picker-panel-container) {
      border-radius: 16px;
    }
  }
}
