// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;
  width: 100%;
}

.optionContainer {
  width: 100%;
  margin: 20px 0;
}
