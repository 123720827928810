// Generated with util/create-component.js
@import 'variables.scss';
@import 'typography.scss';

.root {
  @include font-defaults;

  :global(.ant-form-item) {
    margin-bottom: 3px;
  }

  :global(.ant-select-item-group),
  :global(.ant-form-item-label > label) {
    text-transform: capitalize;
  }
};

.row {
  border-bottom: 1px solid $tbf-lightGrey;
  color: rgba(0, 0, 0, 0.85);
  padding: 10px 0;
}

.submit_section {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    left: 0;
    background: $tbf-white;
    border-radius: 0 0 4px 4px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .submit_button {
    width: 100%;
  }

  .orderRecipients {
    border-bottom: 1px solid $tbf-lightGrey;
    color: rgba(0, 0, 0, 0.85);
    padding-top: 10px;
    padding-bottom: 40px;
  }
