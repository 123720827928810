@import 'variables.scss';
@import 'typography.scss';

.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  .create_button {
    display: flex;
    justify-content: flex-end;
  }
}

.giftCardPurchaseList {
  max-width: 95%;
  text-align: center;
  width: 95%;
  margin: 30px auto; 

  @media (min-width: 1441px) {
    width: 80%;
  }
}

.searchInput {
  width: 50%;
  margin-bottom: 10px;
  border: none !important;
  flex-grow: 1;
}

.container {
  background: white;
}

.actionsLink {
  padding: 0 20px;
  text-align: right;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  color: $tbf-blue;
  cursor: pointer;
  width: auto;
  float: right;
}

.actionLinkText {
  cursor: pointer;
  margin-right: 5px;
}

.downloadIcon {
  margin-right: 5px;
}

.flexContainer {
  display: flex;
  flex-wrap: nowrap;
}

.flexGrow {
  flex-grow: 1;
}

.flexStatic {
  flex: 0 0 auto;
  margin-left: 10px;
}

@media screen and (max-width: 768px) {
  .flexContainer {
    flex-direction: column;
    flex-wrap: wrap;
    display: block;
  }

  .flexStatic, .flexGrow {
    margin-left: 0;
    width: 100%;
  }

  .spaceStyle {
    display: block;
  }

}
