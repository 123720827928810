.divider {
  margin-top: 0;
}

.providerLogo {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}